import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  HttpError,
  ListProps,
  useDataProvider,
  useNotify,
  Loading,
  DataProvider,
} from 'react-admin';
import {
  Card,
  Typography,
  CardContent,
  Box,
  Tabs,
  Tab,
} from '@material-ui/core';
import { keys } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { IProduct } from '../../types';

import { CalculatorBlock } from '../../components/layout/CalculatorBlock';

import { getSliderSettings } from '../../utils/helper';

type loanSettings = {
  amount: number;
  duration: number;
  discount_code?: string;
};

type getPricesProps = {
  productId: number | string;
  params?: {
    amount?: number;
    discount_code?: string;
  };
  isSetLoanSettings?: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px 0 20px 20px',
  },
  box: {
    margin: '0 0 30px 0',
  },
  tab: {
    fontSize: '20px',
  },
  content: {
    padding: '35px !important',
  },
}));

export const CalculatorList: FC<ListProps> = () => {
  const dataProvider: DataProvider = useDataProvider();
  const notify = useNotify();

  const [products, setProducts] = useState([] as IProduct[]);
  const [product, setProduct] = useState('' as string | number);
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState({} as any);
  const [currentAmountPriceList, setCurrentAmountPriceList] = useState(
    {} as any,
  );

  const classes = useStyles();

  const [customAmount, setCustomAmount] = useState(false);
  const [loanSettings, setLoanSettings] = useState({} as loanSettings);

  const getPrices = useCallback(
    ({ productId, params, isSetLoanSettings }: getPricesProps) => {
      if (isSetLoanSettings) {
        setLoading(true);
      }
      dataProvider
        .request(`/wallet/partner/products/${productId}/prices`, params)
        .then(({ data }: any) => {
          setCurrentAmountPriceList(data);
          if (!params?.amount) {
            setPrices(data);
          }

          if (isSetLoanSettings) {
            const minTerm = +keys(data)[0];

            setLoanSettings({
              duration: minTerm,
              amount: +keys(data[minTerm])[0],
            });
          }
          setLoading(false);
        })
        .catch((e: HttpError) => {
          setLoading(false);

          notify(e.body?.ErrorDesc, 'warning');
        });
    },
    [dataProvider, notify],
  );

  const getProducts = useCallback(() => {
    dataProvider
      .sendPost('/third-party/check-idnp', {})
      .then(({ data }: { data: { products: IProduct[] } }) => {
        setProducts(data?.products);
        const defaultId = data?.products.length && data?.products[0].id;
        if (defaultId) {
          setProduct(defaultId);
        }
      })
      .catch((e: HttpError) => {
        setLoading(false);

        notify(e.body?.ErrorDesc, 'warning');
      });
  }, [dataProvider, notify]);

  useEffect(() => {
    setLoading(true);
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (product) {
      getPrices({ productId: product, params: {}, isSetLoanSettings: true });
    }
  }, [getPrices, product]);

  const onGetPricesForAmount = ({
    productId,
    params,
  }: getPricesProps): void => {
    setCustomAmount(true);
    getPrices({ productId, params });
  };

  const currentProduct = useMemo(
    () => products.find((item) => item.id === product) || ({} as IProduct),
    [product, products],
  );

  const sliderSettings = useMemo(() => getSliderSettings(prices), [prices]);

  const currentLoanCondition = useMemo(() => {
    const priceList = customAmount ? currentAmountPriceList : prices;

    return priceList[loanSettings.duration]
      ? priceList[loanSettings.duration][loanSettings.amount]
      : {};
  }, [
    currentAmountPriceList,
    customAmount,
    loanSettings.amount,
    loanSettings.duration,
    prices,
  ]);

  const isLoading = loading || !sliderSettings?.minTerm;
  return (
    <div className={classes.root}>
      <Card>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5">
            Калькулятор
          </Typography>

          {products.length > 0 && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Box
              sx={{ borderBottom: 2, borderColor: 'divider' }}
              className={classes.box}
            >
              <Tabs
                value={product}
                onChange={(e, value) => {
                  setProduct(value);
                }}
              >
                {products.map((item) => {
                  return (
                    <Tab
                      className={classes.tab}
                      key={item.id}
                      label={item.title}
                      value={item.id}
                    />
                  );
                })}
              </Tabs>
            </Box>
          )}
          {isLoading ? (
            <Loading />
          ) : (
            <CalculatorBlock
              currentProduct={currentProduct}
              defaultValueAmount={sliderSettings.minAmount}
              sliderSettings={sliderSettings}
              prices={prices}
              loanSettings={loanSettings}
              setLoanSettings={setLoanSettings}
              currentCondition={currentLoanCondition}
              onGetPricesForAmount={onGetPricesForAmount}
              setCustomAmount={setCustomAmount}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};
