import { FC, useEffect, useState } from 'react';
import { DataProvider, useDataProvider, useTranslate } from 'react-admin';
import { withTypes } from 'react-final-form';
import {
  Button,
  DialogContent,
  DialogContentText,
  FormControl,
} from '@material-ui/core';

import { DialogComponent } from 'components/DialogComponent';
import { SelectField } from 'components/SelectField';
import { SubmitButton } from 'components/SubmitButton';

import { required } from '../../../utils/validation/validation';

export type LoanCancelFormValues = {
  reason_id: number;
};

const { Form } = withTypes<LoanCancelFormValues>();

type LoanCancelProps = {
  onConfirm: (formData: LoanCancelFormValues) => void;
  onClose: () => void;
};

interface DenyReasonResponce {
  id: number;
  value: string;
}

interface LoanDenyReason {
  value: number;
  title: string;
}

export const LoanCancel: FC<LoanCancelProps> = ({ onConfirm, onClose }) => {
  const dataProvider: DataProvider = useDataProvider();

  const translate = useTranslate();
  const [reasons, setReasons] = useState<LoanDenyReason[] | null>(null);

  useEffect(() => {
    dataProvider
      .request('/third-party/loan/deny-reasons')
      .then(({ data: response }: { data: DenyReasonResponce[] }) => {
        const reasons = response.map((reasone) => {
          return { value: reasone.id, title: reasone.value };
        });

        setReasons(reasons);
      });
  }, []);

  return (
    <DialogComponent title="Подтверждение отмены кредита">
      <DialogContent>
        <DialogContentText>
          Необходимо подтверждение действия, после чего кредит будет переведен в
          статус &quot;Отклонен&quot;.
        </DialogContentText>
        {reasons && (
          <Form
            onSubmit={onConfirm}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <FormControl fullWidth>
                    <SelectField
                      defaultValue=""
                      options={reasons}
                      label="loan_request.deny_reason"
                      disabled={false}
                      validate={required}
                      name="reason_id"
                    />
                  </FormControl>

                  <FormControl fullWidth margin="normal">
                    <SubmitButton
                      loading={submitting}
                      title="ra.action.cancel_loan"
                    />
                    <Button onClick={onClose}>
                      {translate('ra.action.undo')}
                    </Button>
                  </FormControl>
                </form>
              );
            }}
          />
        )}
      </DialogContent>
    </DialogComponent>
  );
};
