import { Dispatch, SetStateAction } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import moment from 'moment';

import './styles.css';

interface DateFieldProps
  extends Pick<
    ReactDatePickerProps,
    'disabled' | 'dateFormat' | 'calendarClassName'
  > {
  firstDate: Date | undefined;
  setFirstDate: Dispatch<SetStateAction<Date | undefined>>;
  defaultFirstDayPay: string;
  showOnlyNumbers?: boolean;
}

const ADDITIONAL_DAYS_TO_FIRST_PAY = 14;

export const DateField = ({
  firstDate,
  setFirstDate,
  defaultFirstDayPay,
  disabled,
  dateFormat = 'dd.MM.yyyy',
  calendarClassName,
  ...rest
}: DateFieldProps) => {
  const maxDate = moment(new Date(defaultFirstDayPay)).add(
    ADDITIONAL_DAYS_TO_FIRST_PAY,
    'days',
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <DatePicker
      calendarClassName={calendarClassName}
      dateFormat={dateFormat}
      selected={firstDate}
      onChange={(date: Date) => setFirstDate(date)}
      minDate={new Date(defaultFirstDayPay)}
      maxDate={maxDate.toDate()}
      disabled={disabled}
      {...rest}
    />
  );
};
