import React, { FC } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';

type DialogComponentProps = {
  title: string;
  children: JSX.Element;
};

export const DialogComponent: FC<DialogComponentProps> = ({
  title,
  children,
}) => {
  return (
    <Dialog open style={{ textAlign: 'center', minWidth: '700px' }}>
      <div style={{ minWidth: '600px' }}>
        <DialogTitle>{title}</DialogTitle>
        {children}
      </div>
    </Dialog>
  );
};
