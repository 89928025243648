import { FC, useState } from 'react';
import { Button } from 'react-admin';

import { LoanCancel } from 'pages/Loans/LoanCancel';
import { LoanCancelFormValues } from 'pages/Loans/LoanCancel/LoanCancel';

type LoanCancelButtonProps = {
  id: number;
  onConfirmCancel: (id: number, reasonId: number) => void;
  onClose?: () => void;
};

export const LoanCancelButton: FC<LoanCancelButtonProps> = ({
  id,
  onConfirmCancel,
}) => {
  const [isLoanCancelOpen, setIsLoanCancelOpen] = useState(false);

  const handleOnConfirmCancel = (formData: LoanCancelFormValues): void => {
    onConfirmCancel(id, formData.reason_id);
    setIsLoanCancelOpen(false);
  };

  return (
    <>
      <Button onClick={() => setIsLoanCancelOpen(true)}>
        <>Отменить заявку</>
      </Button>
      {isLoanCancelOpen && (
        <LoanCancel
          onConfirm={handleOnConfirmCancel}
          onClose={() => setIsLoanCancelOpen(false)}
        />
      )}
    </>
  );
};
