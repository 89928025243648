import React, { FC, useState } from 'react';
import { Notification, useTranslate } from 'react-admin';
import { Card, CardContent, Typography } from '@material-ui/core';

import Request from './Request';
import Reset from './Reset';

import { useStyles } from './styles';

export const ForgotPassword: FC = () => {
  const [reset, setReset] = useState(false);
  const classes = useStyles();
  const translate = useTranslate();

  const title = reset
    ? 'resources.auth.forgot_password_reset_title'
    : 'resources.auth.forgot_password_request_title';

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <Typography
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: translate(title) }}
        />
        <CardContent>
          {reset ? <Reset /> : <Request setReset={setReset} />}
        </CardContent>
        <Notification />
      </Card>
    </div>
  );
};
