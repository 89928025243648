import { FC, useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const AdminAuthorization: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const getUrlParams = useCallback((): void => {
    const search = new URLSearchParams(location.search);

    const bearer = search.get('bearer');

    if (bearer) {
      localStorage.setItem('token', bearer);
      history.push('/');
    }
  }, [history, location.search]);

  useEffect(() => {
    getUrlParams();
  }, [getUrlParams]);

  return null;
};
