import React, { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  Filter,
  FilterProps,
  NumberInput,
  TextInput,
  BooleanInput,
} from 'react-admin';

const EmployeeFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput source="username" />
    <TextInput source="last_name" />
    <TextInput source="first_name" />
    <TextInput source="phone" />
    <TextInput source="email" />
    <BooleanInput source="enabled" />
  </Filter>
);

const EmployeeList: FC<ListProps> = (props) => (
  <List
    {...props}
    perPage={25}
    sort={{ field: 'id', order: 'desc' }}
    filters={<EmployeeFilter />}
    exporter={false}
    bulkActionButtons={false}
    title="resources.employees.label"
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="last_name" />
      <TextField source="first_name" />
      <TextField source="phone" />
      <TextField source="email" />
      <BooleanField source="enabled" />
      <EditButton basePath="/employees" />
    </Datagrid>
  </List>
);

export default EmployeeList;
