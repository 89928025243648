import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { withTypes } from 'react-final-form';
import React, { useState, FC } from 'react';
import {
  DataProvider,
  HttpError,
  useDataProvider,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { useStyles } from '../style';

interface ConfirmationLoanRequestProps {
  loanRequestId: number | string;
}

const { Form } = withTypes<{ code: string }>();

const ConfirmationLoanRequest: FC<ConfirmationLoanRequestProps> = () => {
  const dataProvider: DataProvider = useDataProvider();
  const redirect = useRedirect();
  const classes = useStyles();
  const params = useParams<{ id: string }>();

  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const isValidCode = error.length === 0;

  const handleSubmit = (): void => {
    setError('');

    if (code.length < 4) {
      setError('Введите код подтверждения');
      return;
    }

    setLoading(true);

    dataProvider
      .sendPost(
        `/wallet/partner/wallet-loan-requests/${params.id}/confirm-request`,
        { code },
      )
      .then(() => {
        setTimeout(() => {
          redirect(`/loan-request/${params.id}/show`);
        }, 2000);
      })
      .catch((e: HttpError) => {
        if (e.status === 422) {
          setError('Код из SMS введен неправильно');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card>
      <CardHeader>
        <Typography variant="h5">Подтверждение заявки</Typography>
      </CardHeader>
      <CardContent>
        <Typography variant="h6">
          Для подтверждения заявки был отправлен на номер телефона смс с кодом
          подтверждения.
        </Typography>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                margin="normal"
                classes={{ root: classes.formControl }}
              >
                <TextField
                  autoFocus
                  required
                  type="number"
                  label="Введите код"
                  onChange={(event) => {
                    event.target.value = Math.max(
                      0,
                      parseInt(event.target.value, 10),
                    )
                      .toString()
                      .slice(0, 4);

                    setError('');
                    setCode(event.target.value);
                  }}
                  error={!isValidCode}
                  helperText={error}
                />
              </FormControl>
              <Button color="primary" variant="outlined" type="submit">
                {loading && (
                  <CircularProgress size={25} thickness={2} color="inherit" />
                )}{' '}
                Подтвердить
              </Button>
            </form>
          )}
        />
      </CardContent>
    </Card>
  );
};

export default ConfirmationLoanRequest;
