import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 800,
  },
  containerWrapper: {
    padding: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
  },
  formControl: {
    marginBottom: 15,
  },
}));
