import { FC, useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';

import { LoanConfirm } from 'pages/Loans/LoanConfirm';
import { CODE_SIGN_METHOD, DOCUMENT_SIGN_METHOD } from '../../constants';

interface LoanConfirmDropDownButtonProps {
  id: number;
  methods: number[];
}

export const LoanConfirmDropDownButton: FC<LoanConfirmDropDownButtonProps> = ({
  id,
  methods,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [method, setMethod] = useState(0);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChooseMethod = (item: number): void => {
    setMethod(item);
    setIsLoanConfirmOpen(true);
  };

  const [isLoanConfirmOpen, setIsLoanConfirmOpen] = useState(false);

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Подтвердить
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {methods.map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              onChooseMethod(item);
            }}
          >
            {item === CODE_SIGN_METHOD && 'Кодом из смс'}
            {item === DOCUMENT_SIGN_METHOD && 'Загрузкой сканов'}
          </MenuItem>
        ))}
      </Menu>
      {isLoanConfirmOpen && (
        <LoanConfirm
          onClose={() => setIsLoanConfirmOpen(false)}
          id={id}
          method={method}
        />
      )}
    </>
  );
};
