import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import {
  DataProvider,
  HttpError,
  Loading,
  useDataProvider,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { Alert } from '@material-ui/lab';
import { LoanRequestData, LoanRequestResponse } from '../../types';

const LoanRequestShow: FC = () => {
  const params = useParams<{ id: string }>();
  const dataProvider: DataProvider = useDataProvider();
  const redirect = useRedirect();

  const [loading, setLoading] = useState(true);
  const [isLoanUncomplited, setIsLoanUncomplited] = useState(true);
  const [error, setError] = useState<HttpError | string>();

  const [loanRequestResponse, setLoanRequestResponse] = useState<
    LoanRequestResponse | undefined
  >();

  useEffect(() => {
    if (params?.id) {
      dataProvider
        .request(`/wallet/partner/wallet-loan-requests/${params.id}`)
        // `/third-party/requests/${params.id}`
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .then(({ data }) => {
          if (data.LoanData) {
            setLoading(false);
            setLoanRequestResponse(data);
          } else {
            // loan incomplite
            dataProvider
              .request(`/third-party/requests/${params.id}`)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .then(({ data: response }) => {
                data.LoanData = convertApplicationResponse(response);

                setLoading(false);
                setLoanRequestResponse(data);
              })
              .catch((e: HttpError) => {
                setError(e);
                setLoading(false);
              });
          }
        })
        .catch((e: HttpError) => {
          setError(e);
          setLoading(false);
        });
    } else {
      setError('error');
      setLoading(false);
    }
  }, []);

  const resolveTimeUnit = (productType: any): string =>
    productType === 'credit365' ? 'Месяцев' : 'Дней';

  const translate = useTranslate();

  const onUploadClickHandler = () => {
    localStorage.setItem('isHasAccessPhotoUpload', 'true');
    redirect(`/loan-request/${params.id}/upload-passport`);
  };

  if (loading || !loanRequestResponse) return <Loading />;

  if (!loanRequestResponse || error)
    return <Alert severity="error">Заявка не найдена</Alert>;

  return (
    <Card>
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Номер заявки от третьих лиц</TableCell>
              <TableCell>№{params.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Номер заявки / кредита</TableCell>
              <TableCell>№{loanRequestResponse.LoanData?.LoanId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ФИО</TableCell>
              <TableCell>{loanRequestResponse.LoanData?.FullName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>IDNP</TableCell>
              <TableCell>{loanRequestResponse.LoanData?.Idnp}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>
                {translate(
                  `dictionary.loan_request_statuses.${loanRequestResponse.LoanData?.LoanStatus}`,
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Дата подачи заявки</TableCell>
              <TableCell>
                {moment(loanRequestResponse.LoanData?.LoanDate).format(
                  'DD.MM.YYYY HH:mm:ss',
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Запрошенная сумма</TableCell>
              <TableCell>
                {loanRequestResponse.LoanData?.LoanAmount} MDL
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Сумма к выплате</TableCell>
              <TableCell>
                {loanRequestResponse.LoanData?.TotalAmount} MDL
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Длительность</TableCell>
              <TableCell>
                {loanRequestResponse.LoanData?.LoanDuration}{' '}
                {resolveTimeUnit(loanRequestResponse.LoanData?.Type)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Продукт</TableCell>
              <TableCell>{loanRequestResponse.LoanData?.Type}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className="text-right">
        <Button
          component={Link}
          to={{ pathname: '/loan-request/create' }}
          color="primary"
          variant="contained"
        >
          Подать новую заявку
        </Button>
        {isLoanUncomplited && (
          <Button
            color="primary"
            variant="contained"
            onClick={onUploadClickHandler}
          >
            Загрузить файлы
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const convertApplicationResponse = (response: any): LoanRequestData => {
  const { data } = response.data.attributes;

  return {
    FullName: `${data.first_name} ${data.last_name}`,
    Idnp: data.idnp,
    LoanAmount: data.amount,
    LoanId: response._id,
    LoanDate: response.created_at,
    LoanDuration: data.duration,
    LoanStatus: 'request',
    TotalAmount: data.amount,
    Type: data.product_id,
  };
};

export default LoanRequestShow;
