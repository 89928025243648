import { object, string, mixed, date, number, boolean } from 'yup';
import moment from 'moment';

import { isValidPhone } from '../utils/validation/validation';

const ONLY_LATIN = /^[A-Za-z]+$/;
const MAX_BIRTH_DATE = moment().add(-18, 'years');

export const userSchema = object().shape({
  lastName: string()
    .matches(ONLY_LATIN, 'Только латинские символы')
    .required('Поле должно быть обязательно заполненым'),
  firstName: string()
    .matches(ONLY_LATIN, 'Только латинские символы')
    .required('Поле должно быть обязательно заполненым'),
  phone: mixed()
    .required('Номер телефона должен быть обязательно заполненым')
    .transform((value, originalValue, context) => {
      return context.isType(value) && value !== null
        ? parseInt(`373${value}`, 10)
        : '';
    })
    .test('phone-validation', 'Номер телефона введен не верно', (value) =>
      // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      isValidPhone(value),
    ),
  email: string().email('E-mail введен неверно'),
  agreeReal: boolean().oneOf([true], 'Необходимо ваше согласие'),
  approval_pep: boolean(),
});

export const newUserSchema = object().shape({
  birthDate:
    // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    date('Дата введена неправильно')
      .max(
        MAX_BIRTH_DATE,
        'Клиенту на момент подачи заявки на кредит должно быть не менее 18 лет',
      )
      .required('Дата рождения должна быть обязательно заполнена'),
  gender: string().required('Выберите пол'),
});

export const complicatedUserLoanRequestSchema = object().shape({
  education: string().required('Выберите образование'),
  monthlyIncome: string().required('Выберите месячный доход'),
  maritalStatus: string().required('Выберите семейное положение'),
  activityType: string().required('Выберите сферу деятельности'),
});
