import { keys, findLastKey } from 'lodash';

import {
  ACTIVE_LOAN_STATUS,
  REQUEST_LOAN_STATUS,
  SIGNED_LOAN_STATUS,
  CLOSED_LOAN_STATUS,
  REJECTED_LOAN_STATUS,
  GIVE_OUT_PROCESSING_LOAN_STATUS,
  GIVE_OUT_FAILED_LOAN_STATUS,
  AWAITING_SIGN_CONTRACT_STATUS,
  OVERDUE_STATUS,
  AWAITING_COLLECTOR_STATUS,
  COLLECTOR_IN_PROGRESS_STATUS,
  SOLD_STATUS,
} from '../../constants';
import { IMark, IPrices } from '../../types';

export const resolveLoanTerm = (productType: string): string =>
  productType === 'credit7' ? 'д.' : 'м.';

export const prepareLoanTerm = (term: string, productType: string): string =>
  [term, resolveLoanTerm(productType)].join(' ');

export const getLoanStatusText = (status: number): string => {
  switch (status) {
    case REQUEST_LOAN_STATUS:
      return 'Заявка на рассмотрении';
    case SIGNED_LOAN_STATUS:
      return 'Договор подписан';
    case SOLD_STATUS:
    case COLLECTOR_IN_PROGRESS_STATUS:
    case AWAITING_COLLECTOR_STATUS:
    case OVERDUE_STATUS:
    case ACTIVE_LOAN_STATUS:
      return 'Активный';
    case CLOSED_LOAN_STATUS:
      return 'Закрыт';
    case REJECTED_LOAN_STATUS:
      return 'Отклонен';
    case GIVE_OUT_PROCESSING_LOAN_STATUS:
      return 'Идет выдача';
    case GIVE_OUT_FAILED_LOAN_STATUS:
      return 'Ошибка выдачи';
    case AWAITING_SIGN_CONTRACT_STATUS:
      return 'На подписи клиента';
    default:
      return '';
  }
};

export const composeValidators =
  (...validators: any[]): any =>
  (value: string | undefined) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

export const getSliderSettings = (prices: IPrices): any => {
  const minTerm = +keys(prices)[0];
  const maxTerm = findLastKey(prices);
  const minAmount = +keys(prices[minTerm])[0];
  const maxAmount = findLastKey(prices[minTerm]);
  const stepTerm = +keys(prices)[1] - +keys(prices)[0];
  const stepAmount = +keys(prices[minTerm])[1] - +keys(prices[minTerm])[0];

  return {
    maxTerm: maxTerm ? +maxTerm : 0,
    minTerm,
    minAmount,
    maxAmount: maxAmount ? +maxAmount : 0,
    stepAmount,
    stepTerm,
  };
};

export const getSliderMarks = (values: string[]): IMark[] => {
  return values.map((duration, i) => ({
    label: i === 0 || i === values.length - 1 ? +duration : '',
    value: +duration,
  }));
};
