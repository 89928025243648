import { FC, useState } from 'react';
import { Button } from '@material-ui/core';

import { UploadAdditionalDocumentsModal } from './UploadAdditionalDocumentsModal';

export const UploadAdditionalDocumentsButton: FC<{ userId: number }> = ({
  userId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        Загрузить документы
      </Button>
      {isModalOpen && (
        <UploadAdditionalDocumentsModal
          userId={userId}
          onClose={() => setIsModalOpen(!isModalOpen)}
        />
      )}
    </>
  );
};
