import { Grid, Slider } from '@material-ui/core';
import React, { FC } from 'react';

import { IMark } from '../../../types';

type RangeSliderProps = {
  onChange: any;
  onChangeCommitted: any;
  min: number;
  max: number;
  value: number;
  step: number;
  marks: IMark[];
};
export const RangeSlider: FC<RangeSliderProps> = ({
  onChange,
  onChangeCommitted,
  min,
  max,
  value,
  step,
  marks,
}) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs>
        <Slider
          value={value}
          valueLabelDisplay="auto"
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
          min={min}
          max={max}
          step={step}
          marks={marks}
        />
      </Grid>
    </Grid>
  );
};
