import { Dispatch, SetStateAction, useState } from 'react';
import { ILoanRequest } from '../types';

type UseLoanRequestStateType<T> = () => [T, Dispatch<SetStateAction<T>>];

export const useLoanRequestState: UseLoanRequestStateType<
  ILoanRequest
> = () => {
  const [loanRequestState, setLoanRequest] = useState<ILoanRequest>({
    productList: [],
    product: {
      id: '',
      type: '',
      title: '',
    },
    loan: {
      amount: 0,
      duration: 0,
    },
    user: {
      id: null,
      idnp: '',
      files_count: 0,
      lang: 'ro',
      lastName: null,
      firstName: null,
      approval_pep: false,
      blocked_till_date: undefined,
    },
    can_add_promo: false,
    discount_code: '',
    first_payment_date: null,
    settings: {
      exclude_loans_from_contact_center: false,
      simple_loan_request: false,
      is_offline_points_aventus: false,
      sms_verification: false,
      allow_cancel_loan_request: false,
      id: 0,
    },
  });

  return [loanRequestState, setLoanRequest];
};
