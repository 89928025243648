import React, { FC } from 'react';
import { Admin, Resource, DataProvider, useTranslate } from 'react-admin';
import { Route } from 'react-router-dom';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import 'react-phone-number-input/style.css';

import {
  Dashboard,
  LoginPage,
  ForgotPassword,
  Employees,
  LoanRequest,
  Requests,
  Loans,
  AdminAuthorization,
  Calculator,
} from './pages';
import ConfirmationLoanRequest from './components/loanRequest/confirmationLoanRequest';
import UploadPassport from './pages/UploadPassport';

import { Layout } from './components/layout';

import authProvider from './authProvider';

import russianMessages from './utils/i18n/ru';

import { ROLE_ADMINISTRATOR } from './constants';

import './App.css';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru', {
  allowMissing: true,
});

interface AppProps {
  dataProvider: DataProvider;
}

const ADMINISTRATOR_RESOURCES = [
  {
    name: 'employees',
    Component: Employees,
    options: {
      label: 'resources.employees.label',
    },
  },
];

const App: FC<AppProps> = ({ dataProvider }) => {
  const t = useTranslate();

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={Layout}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      loginPage={LoginPage}
      customRoutes={[
        <Route
          exact
          path="/auth/forgot-password"
          component={ForgotPassword}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          noLayout
        />,
        <Route
          exact
          path="/user/auth"
          component={AdminAuthorization}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          noLayout
        />,
        <Route
          exact
          path="/loan-request/:id/confirmation"
          component={ConfirmationLoanRequest}
        />,
        <Route
          exact
          path="/loan-request/:id/upload-passport"
          component={UploadPassport}
        />,
      ]}
      disableTelemetry
    >
      {(permissions) => [
        <Resource
          name="requests"
          {...Requests}
          options={{
            label: t('resources.requests.label'),
          }}
        />,
        <Resource
          name="loans"
          {...Loans}
          options={{
            label: t('resources.loans.label'),
          }}
        />,
        permissions.includes(ROLE_ADMINISTRATOR) &&
          ADMINISTRATOR_RESOURCES.map((item) => (
            <Resource
              key={item.name}
              name={item.name}
              {...item.Component}
              options={{
                label: t(item.options.label),
              }}
            />
          )),
        <Resource
          name="loan-request"
          {...LoanRequest}
          options={{
            label: t('resources.loan_request.label'),
          }}
        />,
        <Resource
          name="calculator"
          {...Calculator}
          options={{
            label: t('resources.calculator.label'),
          }}
        />,
      ]}
    </Admin>
  );
};

export default App;
