import { FC } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button } from '@material-ui/core';

interface UploadAdditionalDocumentsErrorProps {
  error: string;
  onClose: () => void;
  onRetry: () => void;
}

export const UploadAdditionalDocumentsError: FC<
  UploadAdditionalDocumentsErrorProps
> = ({ error, onClose, onRetry }) => {
  return (
    <>
      <div>
        <HighlightOffIcon style={{ color: 'red', fontSize: 80 }} />
      </div>
      <div style={{ fontSize: 18, margin: 15 }}>
        <p>Ошибка загрузки документов.</p>
        <p>Повторите попытку загрузки документов еще раз.</p>
      </div>
      <Button
        color="primary"
        variant="contained"
        onClick={onRetry}
        style={{ margin: 20 }}
      >
        Повторить загрузку
      </Button>
      <Button
        color="secondary"
        variant="contained"
        onClick={onClose}
        style={{ margin: 20 }}
      >
        Закрыть
      </Button>
    </>
  );
};
