import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Step,
  Stepper,
  StepLabel,
  StepContent,
  Typography,
  CardContent,
  Card,
} from '@material-ui/core';
import {
  DataProvider,
  HttpError,
  Loading,
  Notification,
  useDataProvider,
  useNotify,
} from 'react-admin';

import Idnp from '../../components/loanRequest/steps/Idnp';
import { PriceList } from '../../components/loanRequest/steps/PriceList';
import User from '../../components/loanRequest/steps/User';
import GiveOutMethod from '../../components/loanRequest/steps/GiveOutMethod';
import Product from '../../components/loanRequest/steps/Product';

import { IPrices } from '../../types';
import { useLoanRequestState } from '../../hooks';
import { PriceListLine } from '../../components/loanRequest/steps/PriceListLine';

function getSteps(loanRequest: any): string[] {
  const steps = [];

  if (loanRequest.user.idnp) {
    let step = `Проверка клиента по IDNP (${loanRequest.user.idnp})`;
    step += loanRequest.user.id
      ? ` | Повторный клиент #${loanRequest.user.id}`
      : ' | Новый клиент';

    steps.push(step);
  } else {
    steps.push('Проверка клиента по IDNP');
  }

  if (loanRequest.product.type) {
    steps.push(`Выбор продукта (${loanRequest.product.title})`);
  } else {
    steps.push('Выбор продукта');
  }

  steps.push('Выбор суммы и срока');
  steps.push('Персональная информация');
  steps.push('Выбор метода выдачи');

  return steps;
}

const STEPS = [Idnp, Product, PriceList, User, GiveOutMethod];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const min = (data) => Math.min.apply(0, Object.keys(data));

const LoanRequestCreate = () => {
  const classes = useStyles();

  const dataProvider: DataProvider = useDataProvider();
  const notify = useNotify();

  const [activeStep, setActiveStep] = useState(0);
  const [loanRequestState, setLoanRequest] = useLoanRequestState();
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState<IPrices>({});

  const isCreditLine = loanRequestState.product?.type === 'credit_line';

  const steps = useMemo(() => getSteps(loanRequestState), [loanRequestState]);

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const CurrentStep = useMemo(() => {
    if (activeStep === 2) {
      return isCreditLine ? PriceListLine : STEPS[activeStep];
    }

    return STEPS[activeStep];
  }, [activeStep, loanRequestState.product?.type]);

  const alertUser = (e: BeforeUnloadEvent): void => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => window.removeEventListener('beforeunload', alertUser);
  }, []);

  useEffect(() => {
    if (activeStep === 2) {
      setLoading(true);
      dataProvider
        .request(
          `/wallet/partner/products/${loanRequestState.product.id}/prices`,
          {
            user_id: loanRequestState.user.id,
          },
        )
        .then((data: any) => {
          dataProvider
            .request('/third-party/me')
            .then(({ data: response }: any) => {
              setPrice(data.data);
              const duration = min(data.data);

              const amount = min(data.data[duration]);

              setLoanRequest((prevState) => ({
                ...prevState,
                loan: {
                  duration,
                  amount,
                },
                can_add_promo:
                  response.data.attributes.partner.settings
                    .is_offline_points_aventus,
                settings: response.data.attributes.partner.settings,
              }));

              setLoading(false);
            });
        })
        .catch((error: HttpError) => {
          setLoading(false);
          notify(error.body?.ErrorDesc, 'warning');
        });
    }
  }, [
    activeStep,
    loanRequestState.product.id,
    loanRequestState.user.id,
    notify,
  ]);

  const onGetDiscountedPrices = (discount_code: string) => {
    dataProvider
      .request(
        `/wallet/partner/products/${loanRequestState.product.id}/prices`,
        {
          user_id: loanRequestState.user.id,
          discount_code,
        },
      )
      .then((data: any) => {
        setPrice(data.data);
      })
      .catch((error: HttpError) => {
        notify(error.body?.ErrorDesc, 'warning');
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5">
            Подача заявки на кредит
          </Typography>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    <CurrentStep
                      price={price}
                      data={loanRequestState}
                      setLoanRequest={setLoanRequest}
                      nextStep={handleNext}
                      onGetDiscountedPrices={onGetDiscountedPrices}
                    />
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </CardContent>
      </Card>
      <Notification />
    </div>
  );
};

export default LoanRequestCreate;
