import React from 'react';
import { useInput } from 'react-admin';
import DatePicker from 'react-datepicker';

export const DateFilterInput = (props: any) => {
  const {
    input: { value, onChange },
  } = useInput(props);

  const { placeholderText, prefix } = props;

  return (
    <div>
      <span>{prefix} </span>
      <DatePickerCustom
        portalId="root-portal"
        dateFormat="dd.MM.yyyy"
        selected={value ? new Date(value) : null}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText={placeholderText}
        onChange={(date: Date) => {
          if (date) {
            onChange(date.toISOString());
          }
        }}
      />
    </div>
  );
};

function DatePickerCustom(props: any): JSX.Element {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <DatePicker {...props} />
  );
}
