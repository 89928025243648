import React, { FC } from 'react';
import { ListProps } from 'react-admin';
import { Redirect } from 'react-router';

const LoanRequestList: FC<ListProps> = () => (
  <>
    <Redirect to="/loan-request/create" />
  </>
);

export default LoanRequestList;
