import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  DataProvider,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { CloudUpload, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { getApiUrlUploadAdditionalDocs } from 'utils/api';

import DocumentImage from '../../assets/images/document.png';

export const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    cursor: 'pointer',
    color: '#575757',
    marginTop: '20px',
    width: '260px',
    height: '150px',
    maxHeight: '200px',
    '& img': {
      width: '100%',
      height: '120px',
    },
  },
  icon: {
    marginTop: '20px',
    color: '#888',
    fontSize: '38px',
  },
  overlay: {
    background: '#333',
    opacity: '0.6',
  },
  closeIcon: {
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    border: '0px',
    padding: '5px',
  },
  closeButton: {
    right: '4px',
    top: '4px',
    position: 'absolute',
  },
  label: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
  },
  actions: {
    padding: 15,
  },
}));

export interface IDocument {
  id: number;
  type: string; // 'front' | 'back' | 'other';
  label: string;
  src: null | string | ArrayBuffer | any;
  file_name: string;
  original_file: File | null;
  is_image: boolean;
  src_base_64: null | string | ArrayBuffer | any;
}

export const otherDocument: IDocument = {
  id: 0,
  type: 'other',
  label: 'Дополнительный документ',
  src: null,
  file_name: 'other_document',
  original_file: null,
  is_image: false,
  src_base_64: '',
};

export const isFileImage = (file: File): boolean => {
  const fileType = file.type;

  return (
    fileType === 'image/jpeg' ||
    fileType === 'image/jpg' ||
    fileType === 'image/png'
  );
};

const idFrontDocument = 111;
const idBackDocument = 222;

const PassportUpload = () => {
  const dataProvider: DataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const params = useParams<{ id: string }>();
  const isSkipSmsVerification =
    localStorage.getItem('isNeedSmsVerification') === 'false';
  const [loading, setLoading] = React.useState(false);
  const [documents, setDocuments] = React.useState<Array<IDocument>>([
    {
      id: idFrontDocument,
      type: 'front',
      label: 'Лицевая сторона',
      src: null,
      file_name: 'passport_p_front',
      original_file: null,
      is_image: false,
      src_base_64: '',
    },
    {
      id: idBackDocument,
      type: 'back',
      label: 'Обратная сторона',
      src: null,
      file_name: 'passport_p_back',
      original_file: null,
      is_image: false,
      src_base_64: '',
    },
  ]);

  const passportDocuments = documents.filter((document) =>
    [idBackDocument, idFrontDocument].includes(document.id),
  );

  const additionalDocuments = documents.filter(
    (document) => ![idBackDocument, idFrontDocument].includes(document.id),
  );

  React.useEffect(() => {
    if (!localStorage.getItem('isHasAccessPhotoUpload')) {
      redirect('/loan-request/create');
    }
  }, []);

  const redirectToNextStep = () => {
    localStorage.removeItem('isHasAccessPhotoUpload');

    if (isSkipSmsVerification) {
      dataProvider
        .sendPost(
          `/wallet/partner/wallet-loan-requests/${params.id}/confirm-request`,
          { code: null },
        )
        .then(() => {
          setTimeout(() => {
            redirect(`/loan-request/${params.id}/show`);
          }, 2000);
        });
    } else {
      redirect(`/loan-request/${params.id}/confirmation`);
    }
  };

  const handleUploadDocuments = () => {
    const isValid =
      passportDocuments.filter((document) => document.src !== null).length ===
      2;

    if (!isValid) {
      notify('Загрузите все документы', 'info');
      return;
    }

    setLoading(true);

    const promiseDocuments = passportDocuments.map((document) =>
      dataProvider.fetch(
        `/wallet/partner/wallet-loan-requests/${params.id}/document`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            Type: 0,
            Content: document.src_base_64?.split(',')[1],
            Name: document.file_name,
          }),
        },
      ),
    );

    Promise.all(promiseDocuments);

    if (additionalDocuments.length === 0) {
      redirectToNextStep();
      return;
    }

    const formData = new FormData();
    let isFormValid = false;

    additionalDocuments.forEach((document) => {
      if (document.original_file) {
        formData.append('files[]', document.original_file);
        isFormValid = true;
      }
    });

    if (!isFormValid) {
      redirectToNextStep();
      return;
    }

    dataProvider
      .fetch(`/wallet/partner/wallet-loan-requests/${params.id}/files`, {
        method: 'POST',
        body: formData,
      })
      .then((responce: any) => {
        if (responce.data.status !== 200) {
          try {
            const error = JSON.parse(responce.data.body);
            // TODO handle error upload and show file names after BE fix it
            notify(
              `Ошибка загрузки документов. Повторите загрузку документов еще раз.`,
              'warning',
            );
          } catch (error) {
            notify(
              'Ошибка загрузки документов, обратитесь к руководителю',
              'warning',
            );
          }

          setLoading(false);
          return;
        }

        redirectToNextStep();
      })
      .catch((error: any) => {
        alert('Ошибка загрузки документов, обратитесь к руководителю');
      });
  };

  const handleAddDocument = (
    event: React.ChangeEvent<HTMLInputElement>,
    docId: number,
  ) => {
    const reader = new FileReader();
    if (!event.target.files) return;

    const file = event.target.files[0];

    reader.onload = function () {
      setDocuments((prev) =>
        prev.map((doc: IDocument) => {
          if (doc.id === docId) {
            let src;

            if (isFileImage(file)) {
              src = reader.result;
            } else {
              src = DocumentImage;
            }

            return {
              ...doc,
              src,
              original_file: file,
              src_base_64: reader.result,
              is_image: isFileImage(file),
            };
            // eslint-disable-next-line no-else-return
          } else {
            return doc;
          }
        }),
      );
    };

    reader.readAsDataURL(file);
  };

  const handleRemoveDocument = (docId: number) => {
    setDocuments((prev) =>
      prev.map((doc: IDocument) =>
        doc.id === docId ? { ...doc, src: null, original_file: null } : doc,
      ),
    );
  };

  const handleAddNewDocument = () => {
    const newDocument = { ...otherDocument };
    newDocument.id = documents.length + 1;
    newDocument.type = `other_${newDocument.id}`;

    setDocuments([...documents, newDocument]);
  };

  const buttonLabel = isSkipSmsVerification
    ? 'Отправить заявку'
    : 'Загрузить документы';

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Загрузка фото паспорта</Typography>
        <Typography variant="h6">
          Загрузите или сфотографируйте ваше удостоверение личности вместе с
          сопроводительным вкладышем с обеих сторон:
        </Typography>
        <Grid container spacing={2}>
          {passportDocuments.map((document) => (
            <Grid item key={document.type}>
              <Paper variant="outlined" className={classes.root}>
                {document.src ? (
                  <div>
                    <img
                      src={document.src}
                      className={classes.overlay}
                      alt="document"
                    />
                    <Button
                      className={classes.closeIcon}
                      color="secondary"
                      onClick={() => {
                        handleRemoveDocument(document.id);
                      }}
                    >
                      <Close />
                      Удалить
                    </Button>
                  </div>
                ) : (
                  <div className={classes.label}>
                    <input
                      accept="image/jpg, image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, text/plain, text/csv"
                      id={`contained-button-file${document.id}`}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        handleAddDocument(event, document.id);
                      }}
                    />
                    <label
                      htmlFor={`contained-button-file${document.id}`}
                      className={classes.label}
                    >
                      <CloudUpload className={classes.icon} />
                      <p>{document.label}</p>
                    </label>
                  </div>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
        &nbsp;
        <Typography variant="h5">Загрузка дополнительных документов</Typography>
        <Typography variant="h6">
          Загрузите дополнительные фотографии и документы:
        </Typography>
        <Grid container spacing={2} style={{ marginBottom: 15 }}>
          {additionalDocuments.map((document) => (
            <Grid item key={document.type}>
              <Paper variant="outlined" className={classes.root}>
                {document.src ? (
                  <div>
                    <img
                      src={document.src}
                      className={classes.overlay}
                      alt="document"
                    />
                    <Button
                      className={classes.closeIcon}
                      color="secondary"
                      onClick={() => {
                        handleRemoveDocument(document.id);
                      }}
                    >
                      <Close />
                      Удалить
                    </Button>
                  </div>
                ) : (
                  <div className={classes.label}>
                    <input
                      accept="
                        image/jpg,
                        image/jpeg,
                        image/png,
                        application/pdf,
                        application/msword,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        application/vnd.ms-excel,
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        text/plain,
                        text/csv,
                      "
                      id={`contained-button-file${document.id}`}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        handleAddDocument(event, document.id);
                      }}
                    />
                    <label
                      htmlFor={`contained-button-file${document.id}`}
                      className={classes.label}
                    >
                      <CloudUpload className={classes.icon} />
                      <p>{document.label}</p>
                    </label>
                  </div>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
        <div className={classes.label}>
          <Button color="primary" onClick={handleAddNewDocument}>
            Добавить документ
          </Button>
        </div>
      </CardContent>
      <CardActions classes={{ root: classes.actions }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleUploadDocuments}
          disabled={loading}
        >
          {loading && <CircularProgress />}
          {buttonLabel}
        </Button>
      </CardActions>
    </Card>
  );
};

export default PassportUpload;
