import React, { FC } from 'react';
import {
  Create,
  FormTab,
  TextInput,
  BooleanInput,
  required,
  email,
  CreateProps,
  TabbedForm,
  useTranslate,
  HttpError,
  useNotify,
} from 'react-admin';

const EmployeeCreate: FC<CreateProps> = (props) => {
  const translate = useTranslate();
  const notify = useNotify();

  const onFailure = (error: HttpError) => {
    error.body.violations.forEach((item: any) => {
      notify(
        `${translate(`resources.employees.fields.${item.propertyPath}`)}: ${
          item.message
        }`,
        'warning',
      );
    });
  };

  return (
    <Create
      {...props}
      title="resources.employees.titles.create"
      onFailure={onFailure}
    >
      <TabbedForm>
        <FormTab label={translate('resources.Employees.tabs.general')}>
          <TextInput
            autoFocus
            source="username"
            fullWidth
            validate={required()}
          />

          <TextInput source="last_name" fullWidth />
          <TextInput source="first_name" fullWidth />
          <TextInput source="phone" fullWidth validate={required()} />
          <TextInput source="email" fullWidth validate={email()} />
          <TextInput source="password" fullWidth />
          <BooleanInput source="enabled" fullWidth />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default EmployeeCreate;
