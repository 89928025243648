import { FC, useState } from 'react';
import { DataProvider, useDataProvider, useTranslate } from 'react-admin';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import {
  IDocument,
  isFileImage,
  useStyles,
} from 'pages/UploadPassport/UploadPassport';
import { getApiUrlUploadAdditionalDocs } from 'utils/api';
import { Close, CloudUpload } from '@material-ui/icons';

import DocumentImage from '../../../assets/images/document.png';

export const additionalDocument: IDocument = {
  id: 0,
  type: 'other',
  label: 'Дополнительный документ',
  src: null,
  file_name: 'other_document',
  original_file: null,
  is_image: false,
  src_base_64: '',
};

interface UploadAdditionalDocumentsComponentProps {
  userId: number;
  onClose: () => void;
  isDocsUploadedSuccessfully: boolean;
  setDocsUploadError: (error: string) => void;
  setIsDocsUploadedSuccessfully: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
}

export const UploadAdditionalDocumentsComponent: FC<
  UploadAdditionalDocumentsComponentProps
> = ({
  userId,
  onClose,
  isDocsUploadedSuccessfully,
  setDocsUploadError,
  setIsDocsUploadedSuccessfully,
  setIsLoading,
}) => {
  const dataProvider: DataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();

  const [additionalDocuments, setAdditionalDocuments] = useState<
    Array<IDocument>
  >([
    {
      id: 1,
      type: 'other',
      label: 'Дополнительный документ',
      src: null,
      file_name: 'other_document',
      original_file: null,
      is_image: false,
      src_base_64: '',
    },
  ]);

  const onUploadHandler = () => {
    const formData = new FormData();
    let filesAdded = false;

    additionalDocuments.forEach((document) => {
      if (document.original_file) {
        formData.append('files[]', document.original_file);
        filesAdded = true;
      }
    });

    if (filesAdded) {
      dataProvider
        .fetch(getApiUrlUploadAdditionalDocs(userId.toString()), {
          method: 'POST',
          body: formData,
        })
        .then((responce: any) => {
          if (responce.data.status !== 204) {
            try {
              const error = JSON.parse(responce.data.body);

              setDocsUploadError(
                `Ошибка загрузки документов. Повторите попытку загрузки документов еще раз.`,
              );
            } catch (error) {
              setDocsUploadError(
                'Ошибка загрузки документов, обратитесь к руководителю',
              );
            }

            return;
          }

          setIsDocsUploadedSuccessfully(!isDocsUploadedSuccessfully);
        })
        .catch((error: any) => {
          setDocsUploadError(
            'Ошибка загрузки документов. Повторите загрузку документов еще раз.',
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsDocsUploadedSuccessfully(!isDocsUploadedSuccessfully);
    }
  };

  const handleAddDocument = (
    event: React.ChangeEvent<HTMLInputElement>,
    docId: number,
  ) => {
    const reader = new FileReader();
    if (!event.target.files) return;

    const file = event.target.files[0];

    reader.onload = function () {
      setAdditionalDocuments((prev) =>
        prev.map((doc: IDocument) => {
          if (doc.id === docId) {
            let src;

            if (isFileImage(file)) {
              src = reader.result;
            } else {
              src = DocumentImage;
            }

            return { ...doc, src, original_file: file };
            // eslint-disable-next-line no-else-return
          } else {
            return doc;
          }
        }),
      );
    };

    reader.readAsDataURL(file);
  };

  const handleRemoveDocument = (docId: number) => {
    setAdditionalDocuments((prev) =>
      prev.map((doc: IDocument) =>
        doc.id === docId ? { ...doc, src: null, original_file: null } : doc,
      ),
    );
  };

  const handleAddNewDocument = () => {
    const newDocument = { ...additionalDocument };
    newDocument.id = additionalDocuments.length + 1;
    newDocument.type = `other_${newDocument.id}`;

    setAdditionalDocuments([...additionalDocuments, newDocument]);
  };

  return (
    <>
      <Typography variant="h6">
        Загрузите дополнительные фотографии и документы:
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ marginBottom: 15, width: '100%' }}
      >
        {additionalDocuments.map((document) => (
          <Grid item key={document.type}>
            <Paper variant="outlined" className={classes.root}>
              {document.src ? (
                <div>
                  <img
                    src={document.src}
                    className={classes.overlay}
                    alt="document"
                  />
                  <Button
                    className={classes.closeIcon}
                    color="secondary"
                    onClick={() => {
                      handleRemoveDocument(document.id);
                    }}
                  >
                    <Close />
                    Удалить
                  </Button>
                </div>
              ) : (
                <div className={classes.label}>
                  <input
                    accept="image/jpg,
                    image/jpeg,
                    image/png,
                    application/pdf,
                    application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                    application/vnd.ms-excel,
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    text/plain,
                    text/csv"
                    id={`contained-button-file${document.id}`}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      handleAddDocument(event, document.id);
                    }}
                  />
                  <label
                    htmlFor={`contained-button-file${document.id}`}
                    className={classes.label}
                  >
                    <CloudUpload className={classes.icon} />
                    <p>{document.label}</p>
                  </label>
                </div>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
      <div className={classes.label}>
        <Button color="primary" onClick={handleAddNewDocument}>
          Добавить документ
        </Button>
      </div>
      <Button
        color="primary"
        variant="contained"
        style={{ margin: 15 }}
        onClick={onUploadHandler}
      >
        {translate('ra.action.upload_docs')}
      </Button>
      <Button color="secondary" variant="contained" onClick={onClose}>
        {translate('ra.action.undo')}
      </Button>
    </>
  );
};
