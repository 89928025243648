import React, { useState, FC } from 'react';
import { Notification, useTranslate, useLogin, useNotify } from 'react-admin';
import { withTypes } from 'react-final-form';
import { useLocation, Link } from 'react-router-dom';
import { Avatar, Card, CardActions } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';

import { FORGOT_PASSWORD_PATH } from '../../../constants';

import { FormField } from '../../../components/FormField';
import { SubmitButton } from '../../../components/SubmitButton';

import { required } from '../../../utils/validation/validation';

import { useStyles } from './styles';

type FormValues = {
  username?: string;
  password?: string;
};

const { Form } = withTypes<FormValues>();

const Login: FC = () => {
  const [loading, setLoading] = useState(false);

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();

  const location = useLocation<{ nextPathname: string } | null>();

  const onHandleSubmit = (auth: FormValues): void => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : '/').catch(
      (error: Error) => {
        setLoading(false);
        notify(error?.message || 'ra.auth.sign_in_error', 'warning');
      },
    );
  };

  return (
    <Form
      onSubmit={onHandleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <FormField
                    name="username"
                    label="ra.auth.username"
                    disabled={loading}
                    validate={required}
                    type="text"
                  />
                </div>
                <div className={classes.input}>
                  <FormField
                    name="password"
                    label="ra.auth.password"
                    disabled={loading}
                    type="password"
                    validate={required}
                  />
                </div>
                <div className={classes.input}>
                  <Link
                    to={FORGOT_PASSWORD_PATH}
                    replace
                    className={classes.forgot_password}
                  >
                    {translate('resources.auth.forgot_password')}
                  </Link>
                </div>
              </div>
              <CardActions className={classes.actions}>
                <SubmitButton loading={loading} title="ra.auth.sign_in" />
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

export const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createTheme()}>
    <Login {...props} />
  </ThemeProvider>
);
