import moment from 'moment';

const LoanRequestPreparer = (data) => {
  const isNewUser = data?.user?.id === null;

  let additionalData = {};

  if (isNewUser) {
    additionalData = {
      birth_date: moment(data.user.birthDate).format('YYYY-MM-DD'),
      gender: data.user.gender,
      education: data.user.education,
      marital_status: data.user.maritalStatus,
      activity_type: data.user.activityType,
      monthly_income: data.user.monthlyIncome,
    };
  }

  return {
    idnp: data.user.idnp,
    last_name: data.user.lastName,
    first_name: data.user.firstName,
    main_phone_number: parseInt(data.user.phone, 10),
    email: data.user.email,
    lang: data.user.lang,
    product_id: data.product.id,
    amount: data.loan.amount,
    duration: data.loan.duration,
    discount_code: data.discount_code,
    giveout_method: parseInt(data.giveoutMethod, 10),
    commodity_category: data.commodity_category,
    commodity_name: data.commodity_name,
    account: data.account ? data.account.trim() : null,
    approval_pep: data.user.approval_pep ? 0 : 1,
    first_payment_date: data.first_payment_date,
    ...additionalData,
  };
};

export default LoanRequestPreparer;
