import { FC, useState } from 'react';
import { Button } from 'react-admin';

import { LoanConfirm } from 'pages/Loans/LoanConfirm';

export const LoanConfirmButton: FC<{ id: number; methods: number[] }> = ({
  id,
  methods,
}) => {
  const [isLoanConfirmOpen, setIsLoanConfirmOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsLoanConfirmOpen(true)}>
        <>Подтвердить</>
      </Button>
      {isLoanConfirmOpen && (
        <LoanConfirm
          onClose={() => setIsLoanConfirmOpen(false)}
          id={id}
          method={methods[0]}
        />
      )}
    </>
  );
};
