import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslate } from 'react-admin';

interface IOption {
  title: string;
  value: number | string;
}

type FieldProps = {
  options: IOption[];
  label: string;
  disabled: boolean;
  validate: any;
  name: string;
  defaultValue: string;
};

export const SelectField: FC<FieldProps> = ({
  options,
  disabled,
  validate,
  name,
  label,
  defaultValue,
}) => {
  const t = useTranslate();

  return (
    <Field
      name={name}
      validate={validate}
      disabled={disabled}
      initialValue={defaultValue}
    >
      {({ input, meta: { touched, error } }) => {
        return (
          <>
            <InputLabel>{t(label)}</InputLabel>
            <Select
              error={!!(touched && error)}
              {...input}
              defaultValue={defaultValue}
            >
              {options.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </>
        );
      }}
    </Field>
  );
};
