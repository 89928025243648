import React, { useState, FC } from 'react';
import { FormControl, Grid } from '@material-ui/core';
import {
  useDataProvider,
  Loading,
  useNotify,
  Notification,
  HttpError,
  DataProvider,
  useTranslate,
} from 'react-admin';
import { withTypes } from 'react-final-form';
import moment from 'moment';

import { ErrorMessage } from 'components/ErrorMessage';
import { FormField } from 'components/FormField';
import { SubmitButton } from 'components/SubmitButton';
import { SelectField } from 'components/SelectField';

import {
  isValidIdnp,
  required,
  minValue,
} from '../../../../utils/validation/validation';
import { composeValidators } from '../../../../utils/helper';
import { onlyNums } from '../../../../utils/parsers';

import { LANGUAGE_LIST } from '../../../../constants';

import { ICurrentStepProps } from '../../../../types';

const IDNP_LENGTH = 13;

type FormValues = {
  language: string;
  idnp: string;
};

const { Form } = withTypes<FormValues>();

const Idnp: FC<ICurrentStepProps> = ({ data, setLoanRequest, nextStep }) => {
  const dataProvider: DataProvider = useDataProvider();
  const notify = useNotify();
  const t = useTranslate();

  const [loading, setLoading] = useState(false);
  let errorMessage = '';

  if (data.user && data.user.blocked_till_date) {
    const dateBlockedTill = new Date(data.user.blocked_till_date);
    const now = new Date();
    const diffTime = Math.abs(dateBlockedTill.getTime() - now.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 365) {
      errorMessage = t('errors.employee_blocked');
    } else {
      const formattedDate = moment(dateBlockedTill).format('DD.MM.YYYY');

      errorMessage = t('errors.employee_blocked_till_date', {
        date: formattedDate,
      });
    }
  }

  const onHandleSubmit = (values: FormValues) => {
    setLoading(true);

    dataProvider
      .sendPost('/third-party/check-idnp', { idnp: values.idnp })
      .then((response: any) => {
        const resp = response.data;

        setLoanRequest({
          ...data,
          user: {
            ...data.user,
            idnp: values.idnp,
            id: resp.user ? resp.user.id : null,
            lastName: resp.user ? resp.user.last_name : null,
            firstName: resp.user ? resp.user.first_name : null,
            files_count: resp.files_count,
            lang: values.language,
            blocked_till_date: resp.user ? resp.user.blocked_till_date : null,
          },
          productList: response.data.products,
        });

        if (resp.user?.blocked_till_date) {
          setLoading(false);
        } else {
          nextStep();
        }
      })
      .catch((error: HttpError) => {
        notify(`Ошибка проверки IDNP, статус: ${error.status}`, 'warning');
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid item>
        <Form
          onSubmit={onHandleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormControl fullWidth margin="normal">
                <SelectField
                  defaultValue={data.user.lang}
                  options={LANGUAGE_LIST}
                  label="loan_request.client_language"
                  disabled={loading}
                  validate={required}
                  name="lang"
                />
              </FormControl>
              <FormControl fullWidth>
                <FormField
                  name="idnp"
                  label="loan_request.idnp_label"
                  disabled={loading}
                  validate={composeValidators(
                    required,
                    minValue(IDNP_LENGTH),
                    isValidIdnp,
                  )}
                  maxLength={IDNP_LENGTH}
                  parse={onlyNums}
                  type="text"
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <SubmitButton loading={loading} title="resources.auth.next" />
              </FormControl>
              <Notification />
            </form>
          )}
        />
      </Grid>
      <Grid container>
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </Grid>
    </Grid>
  );
};

export default Idnp;
