import {
  useDataProvider,
  useNotify,
  HttpError,
  DataProvider,
} from 'react-admin';
import React, { useEffect, useState, useMemo, ChangeEvent } from 'react';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TextField,
} from '@material-ui/core';
import moment from 'moment';

import Offer from '../../offer';
import {
  getSliderSettings,
  resolveLoanTerm,
  getSliderMarks,
} from '../../../../utils/helper';
import { CURRENCY } from '../../../../constants';
import { ICurrentStepProps } from '../../../../types';
import { RangeSlider } from '../../../common/RangeSlider';

import 'react-datepicker/dist/react-datepicker.css';

export const PriceListLine = ({
  data,
  setLoanRequest,
  nextStep,
  price,
}: ICurrentStepProps) => {
  const dataProvider: DataProvider = useDataProvider();
  const notify = useNotify();

  const [firstDate, setFirstDate] = useState<Date>();
  const [loanCondition, setLoanCondition] = useState<any>(price);
  const [duration, setDuration] = useState(data.loan.duration);
  const [amount, setAmount] = useState(data.loan.amount.toString());

  useEffect(() => {
    if (firstDate) {
      setLoanRequest({
        ...data,
        first_payment_date: moment(firstDate).format('YYYY-MM-DD'),
      });
    }
  }, [firstDate]);

  const savePriceList = (amount: number, duration: number): void => {
    setLoanRequest({
      ...data,
      loan: {
        amount: Number(amount),
        duration,
      },
    });
  };

  const onGetPricesForAmount = (amount: number): void => {
    const params: {
      user_id: number | null;
      amount: number;
    } = {
      user_id: data.user.id,
      amount,
    };

    dataProvider
      .request(`/wallet/partner/products/${data.product.id}/prices`, params)
      .then((response: any) => {
        setLoanCondition(response.data);
        savePriceList(amount, duration);
      })
      .catch((error: HttpError) => {
        notify(error.body.ErrorDesc, 'warning');
      });
  };

  const amountList = useMemo(
    () =>
      price[data.loan.duration] ? Object.keys(price[data.loan.duration]) : [],
    [data.loan.duration, price],
  );

  const sliderSettings = useMemo(() => getSliderSettings(price), [price]);

  const currentLoanCondition = useMemo(
    () =>
      loanCondition[data.loan.duration]
        ? loanCondition[data.loan.duration][data.loan.amount]
        : {},
    [data.loan.duration, data.loan.amount, loanCondition],
  );

  const onBlurCustomAmount = (value: number): void => {
    let current = value;
    if (value > sliderSettings.maxAmount) {
      setAmount(sliderSettings.maxAmount);
      current = sliderSettings.maxAmount;
    } else if (value < sliderSettings.minAmount) {
      setAmount(sliderSettings.minAmount);
      current = sliderSettings.minAmount;
    } else {
      setAmount(value.toString());
    }

    onGetPricesForAmount(current);
  };

  const firstDatePayment = moment(
    currentLoanCondition?.first_payment_date,
  ).format('DD.MM.YYYY');

  if (data.loan.duration === 0 || !currentLoanCondition) return null;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',

                marginBottom: '10px',
              }}
            >
              <Typography
                style={{
                  marginRight: '10px',
                }}
              >
                Выберите сумму:{' '}
              </Typography>
              <TextField
                type="number"
                inputProps={{ step: '0.1', lang: 'en-US' }}
                id="standard-basic"
                value={amount.toString()}
                // defaultValue={data.loan.amount}
                onBlur={(e) => onBlurCustomAmount(+e.target.value)}
                onChange={(e) => setAmount(e.target.value)}
              />{' '}
              <Typography
                style={{
                  marginLeft: '10px',
                }}
              >
                {CURRENCY}
              </Typography>
            </div>
            <RangeSlider
              value={Number(amount)}
              onChange={(event: ChangeEvent, amount: number) => {
                setAmount(amount.toString());
                setLoanCondition(price);
              }}
              onChangeCommitted={() => savePriceList(Number(amount), duration)}
              min={sliderSettings.minAmount}
              max={sliderSettings.maxAmount}
              step={sliderSettings.stepAmount}
              marks={getSliderMarks(amountList)}
            />
          </div>
          <div>
            <Typography gutterBottom>
              Выберите срок: {data.loan.duration}
              {resolveLoanTerm(data.product.type)}
            </Typography>
            <RangeSlider
              value={duration}
              onChange={(event: ChangeEvent, v: number) => {
                setDuration(v);
              }}
              onChangeCommitted={() => savePriceList(Number(amount), duration)}
              min={sliderSettings.minTerm}
              max={sliderSettings.maxTerm}
              step={sliderSettings.stepTerm}
              marks={getSliderMarks(Object.keys(price)) || false}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Занимаемая сумма:</TableCell>
                <TableCell>
                  {data.loan.amount} {CURRENCY}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Сумма к возврату:</TableCell>
                <TableCell>
                  {currentLoanCondition?.return_amount || '-'} {CURRENCY}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ежемесячный платеж</TableCell>
                <TableCell>
                  {currentLoanCondition?.first_payment_amount || '-'} {CURRENCY}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Первый платеж</TableCell>
                <TableCell>{firstDatePayment || '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Срок кредита</TableCell>
                <TableCell>
                  {data.loan.duration}
                  {resolveLoanTerm(data.product.type)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Дата возврата</TableCell>
                <TableCell>
                  {moment(currentLoanCondition?.return_date).format(
                    'DD.MM.YYYY',
                  ) || '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Offer
                    productId={data.product.id}
                    duration={data.loan.duration}
                    amount={data.loan.amount}
                    userId={data.user.id}
                    language={data.user.lang || 'ro'}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Button color="primary" onClick={nextStep}>
            Далее
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
