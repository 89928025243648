import { FC } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Button, Grid } from '@material-ui/core';

interface UploadAdditionalDocumentsSuccessProps {
  onClose: () => void;
}

export const UploadAdditionalDocumentsSuccess: FC<
  UploadAdditionalDocumentsSuccessProps
> = ({ onClose }) => {
  return (
    <>
      <div>
        <CheckCircleOutlineIcon style={{ color: 'green', fontSize: 80 }} />
      </div>
      <div style={{ fontSize: 18, margin: 15 }}>
        Документы успешно загружены
      </div>
      <Button
        color="primary"
        variant="contained"
        onClick={onClose}
        style={{ margin: 20 }}
      >
        Закрыть
      </Button>
    </>
  );
};
