import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';

interface SubmitButtonProps {
  loading: boolean;
  title: string;
}

export const SubmitButton: FC<SubmitButtonProps> = ({ loading, title }) => {
  const translate = useTranslate();

  return (
    <Button
      variant="contained"
      type="submit"
      color="primary"
      disabled={loading}
      fullWidth
    >
      {loading && <CircularProgress size={25} thickness={2} />}
      {translate(title)}
    </Button>
  );
};
