import { AuthProvider } from 'react-admin';
import JWTToken from './JWTToken';

const isPublicUrl = (hash: string): boolean =>
  hash === '#/auth/forgot-password';

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_URL}/third-party/auth`,
      {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      },
    );

    return fetch(request)
      .then((response) => {
        if (response.status >= 400) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ message: false });
        }

        return response.json();
      })
      .then((auth) => {
        JWTToken.setToken(auth.token, auth.refresh_token);
      });
  },
  logout: () => {
    window.document.title = 'Aventus Finance Admin';
    JWTToken.destroy();

    return Promise.resolve();
  },
  checkError: (error) => {
    const { status } = error;

    if (status === 401 || status === 403) {
      window.document.title = 'Aventus Finance Admin';
      JWTToken.destroy();
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: false });
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    if (isPublicUrl(window.location.hash)) {
      return Promise.resolve();
    }

    if (JWTToken.hasToken() && JWTToken.isNotExpired()) {
      return Promise.resolve();
    }

    if (JWTToken.hasRefreshToken()) {
      const request = new Request(
        `${process.env.REACT_APP_API_URL}/third-party/token/refresh`,
        {
          method: 'POST',
          body: JSON.stringify({
            refresh_token: JWTToken.getRefreshToken(),
          }),
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      return new Promise((resolve, reject) => {
        fetch(request)
          .then((response) => {
            if (response.status >= 400) {
              window.document.title = 'Aventus Finance Admin';
              JWTToken.destroy();
              return reject();
            }

            return response.json();
          })
          .then((auth) => {
            JWTToken.setToken(auth.token, auth.refresh_token);
            return resolve();
          })
          .catch(() => reject());
      });
    }

    return Promise.reject();
  },
  getPermissions: () => {
    if (isPublicUrl(window.location.hash)) {
      return Promise.resolve([]);
    }

    if (!JWTToken.hasToken()) {
      return Promise.reject();
    }

    return Promise.resolve(JWTToken.getUser().role);
  },
  getIdentity: () => {
    const user = JWTToken.getUser();

    return Promise.resolve({
      id: user.id,
      fullName: user.fullName,
    });
  },
};

export default authProvider;
