import {
  FormControl,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import React, { useEffect, useState, FC, ChangeEvent } from 'react';
import { useDataProvider, Loading, DataProvider } from 'react-admin';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import { UploadAdditionalDocumentsButton } from 'pages/Loans/UploadAdditionalDocuments';
import SelectDictionary from './SelectDictionary';
import { Input } from '../../../Input';
import { CheckboxField } from '../../../CheckboxField';

import { onlyNums } from '../../../../utils/parsers';
import * as dictionaries from '../../../../utils/dictionaries';

import { ICurrentStepProps, IPartner } from '../../../../types';
import {
  newUserSchema,
  userSchema,
  complicatedUserLoanRequestSchema,
} from '../../../../schemas';

const MAX_DATE_BIRTH = moment().add(-18, 'years');

type UserFormValues = {
  lastName: string;
  firstName: string;
  agreeReal: boolean;
  approval_pep: boolean;
  birthDate: string;
  phone: string;
  email: string;
  gender: string;
  maritalStatus: string;
  education: string;
  activityType: string;
  monthlyIncome: string;
};

const User: FC<ICurrentStepProps> = ({ data, setLoanRequest, nextStep }) => {
  const dataProvider: DataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [partner, setPartner] = useState<IPartner | null>(null);
  const [noClientEmail, setNoClientEmail] = useState<boolean>(true);

  useEffect(() => {
    dataProvider
      .request('/third-party/me')
      .then(({ data: response }: any) => {
        setPartner(response.data.attributes.partner);
        // setUserId(response.id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const isNewUser = data.user.id === null;
  const userId = data.user.id || 0;
  const isSimpleLoanRequest = partner?.settings?.simple_loan_request;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    shouldUnregister: true,
    mode: 'onChange',
    resolver: yupResolver(
      // eslint-disable-next-line no-nested-ternary
      isNewUser && isSimpleLoanRequest
        ? userSchema.concat(newUserSchema)
        : isNewUser && !isSimpleLoanRequest
        ? userSchema
            .concat(newUserSchema)
            .concat(complicatedUserLoanRequestSchema)
        : userSchema,
    ),
    defaultValues: {
      agreeReal: false,
      approval_pep: false,
    } as UserFormValues,
  });

  const onSubmit = (formData: any): void => {
    setLoanRequest((prev: any) => ({
      ...prev,
      user: {
        ...prev.user,
        ...formData,
        gender: +formData.gender,
        maritalStatus: +formData.maritalStatus,
        education: +formData.education,
        activityType: +formData.activityType,
        monthlyIncome: +formData.monthlyIncome,
      },
    }));

    nextStep();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <Input
              {...register('lastName')}
              // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
              // @ts-ignore
              label="Фамилия"
              defaultValue={data.user.lastName}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Input
              {...register('firstName')}
              // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
              // @ts-ignore
              label="Имя"
              defaultValue={data.user.firstName}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          </FormControl>
          {isNewUser ? (
            <FormControl fullWidth margin="normal">
              <Input
                {...register('birthDate')}
                // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
                // @ts-ignore
                type="date"
                label="Дата рождения"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={MAX_DATE_BIRTH.format('YYYY-MM-DD')}
                max={MAX_DATE_BIRTH.format('YYYY-MM-DD')}
                error={!!errors.birthDate}
                helperText={errors.birthDate?.message}
              />
            </FormControl>
          ) : (
            ''
          )}
          <FormControl fullWidth margin="normal">
            <Input
              {...register('phone')}
              // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
              // @ts-ignore
              label="Телефон"
              error={!!errors.phone}
              helperText={errors.phone?.message}
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                const val = onlyNums(e.target.value);
                e.target.value = val.toString().slice(0, 8);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+373</InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <FormControlLabel
              control={
                <Checkbox
                  checked={noClientEmail}
                  name="hasEmail"
                  onChange={(e) => {
                    setNoClientEmail(e.target.checked);
                  }}
                />
              }
              label={<span>У клиента отсутствует адрес электронной почты</span>}
            />
            {!noClientEmail && (
              <Input
                {...register('email')}
                // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
                // @ts-ignore
                label="E-mail"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          </FormControl>
          {isNewUser ? (
            <FormControl fullWidth margin="normal" error={!!errors.gender}>
              <InputLabel>Пол</InputLabel>
              <Select {...register('gender')} defaultValue="">
                <MenuItem value={2}>Мужской</MenuItem>
                <MenuItem value={3}>Женский</MenuItem>
              </Select>
              <FormHelperText>{errors.gender?.message}</FormHelperText>
            </FormControl>
          ) : (
            ''
          )}
        </Grid>
        {isNewUser && !isSimpleLoanRequest ? (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" error={!!errors.education}>
              <InputLabel>Образование</InputLabel>
              <SelectDictionary
                register={register('education')}
                dictionary={dictionaries.educationList}
              />
              <FormHelperText>{errors.education?.message}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              error={!!errors.maritalStatus}
            >
              <InputLabel>Семейное положение</InputLabel>
              <SelectDictionary
                register={register('maritalStatus')}
                dictionary={dictionaries.maritalStatusList}
              />
              <FormHelperText>{errors.maritalStatus?.message}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              error={!!errors.activityType}
            >
              <InputLabel>Сфера деятельности</InputLabel>
              <SelectDictionary
                register={register('activityType')}
                dictionary={dictionaries.jobPositionList}
              />
              <FormHelperText>{errors.activityType?.message}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              error={!!errors.monthlyIncome}
            >
              <InputLabel>Месячный доход</InputLabel>
              <SelectDictionary
                register={register('monthlyIncome')}
                dictionary={dictionaries.monthlyIncomeList}
              />
              <FormHelperText>{errors.monthlyIncome?.message}</FormHelperText>
            </FormControl>
          </Grid>
        ) : (
          ''
        )}

        <div>
          <CheckboxField
            error={errors?.agreeReal?.message}
            setValue={setValue}
            name="agreeReal"
            register={register('agreeReal')}
            label="Подтверждаю, что я являюсь реальным бенефициаром данного кредита"
            tooltip="Выгодоприобретающий собственник – физическое лицо, которое в конечном счете владеет физическим либо юридическим лицом."
          />

          <CheckboxField
            error={errors?.approval_pep?.message}
            setValue={setValue}
            name="approval_pep"
            register={register('approval_pep')}
            label="Подтверждаю, что я НЕ политически значимый человек"
            tooltip="Политически уязвимые лица – физические лица, которые исполняют или исполняли в течение прошедшего года важные государственные должности на национальном и/или международном уровне, и члены руководящих органов политических партий."
          />

          {!isNewUser && <UploadAdditionalDocumentsButton userId={userId} />}
        </div>

        <Grid item xs={12}>
          <Button type="submit" color="primary" variant="contained">
            Далее
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default User;
