import LoanRequestIcon from '@material-ui/icons/Money';

import LoanRequestCreate from './LoanRequestCreate';
import LoanRequestList from './LoanRequestList';
import LoanRequestShow from './LoanRequestShow';

export const exportedLoanRequest = {
  show: LoanRequestShow,
  list: LoanRequestList,
  create: LoanRequestCreate,
  icon: LoanRequestIcon,
};
