import React, { FC } from 'react';
import {
  Datagrid,
  Filter,
  FilterProps,
  FunctionField,
  List,
  ListProps,
  NumberInput,
  ReferenceField,
  ShowButton,
  TextField,
  usePermissions,
} from 'react-admin';

import { resolveLoanTerm } from '../../utils/helper';
import { ROLE_ADMINISTRATOR } from '../../constants';

const RequestFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    {/* <BooleanInput source="isConfirmed" label="Заявка подтверждена" /> */}
  </Filter>
);

const RequestsList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions();

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'id', order: 'desc' }}
      filters={<RequestFilter />}
      bulkActionButtons={false}
      exporter={false}
      title="resources.requests.label"
      filterDefaultValues={{ isConfirmed: false }}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="data.last_name" label="Фамилия" />
        <TextField source="data.first_name" label="Имя" />
        <TextField source="data.amount" label="Сумма" />
        <FunctionField
          source="data.term"
          label="Срок"
          render={(record: any) => {
            return [
              record.data.duration,
              resolveLoanTerm(record.data.product_id),
            ].join(' ');
          }}
        />
        <TextField source="created_at" />
        <FunctionField
          source="confirmed"
          render={(record: any) => {
            return record.confirmed ? 'Да' : 'Нет';
          }}
        />
        {permissions === ROLE_ADMINISTRATOR && (
          <ReferenceField
            label="Сотрудник"
            source="employee_id"
            reference="employees"
          >
            <FunctionField
              render={(record: any) => {
                return `${record.first_name} ${record.last_name}`;
              }}
            />
          </ReferenceField>
        )}
        <ShowButton basePath="/loan-request" />
      </Datagrid>
    </List>
  );
};

export default RequestsList;
