import { FC } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

type CheckboxFieldProps = {
  label: string;
  register: any;
  name?: string;
  setValue: any;
  error?: string;
  tooltip?: string;
};

export const CheckboxField: FC<CheckboxFieldProps> = ({
  label,
  register,
  name,
  setValue,
  error,
  tooltip,
}) => {
  return (
    <div style={{ marginBottom: '10px' }}>
      <FormControlLabel
        control={
          <Checkbox
            {...register}
            name={name}
            onChange={(e) => {
              setValue(name, e.target.checked);
            }}
          />
        }
        label={
          <span>
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoOutlinedIcon
                  style={{ marginLeft: '5px', fontSize: '14px' }}
                />
              </Tooltip>
            )}
          </span>
        }
      />
      <FormHelperText error>{error}</FormHelperText>
    </div>
  );
};
