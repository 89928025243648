import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  contractTable: {
    '& table': {
      width: '100%',
      margin: '20px 0',
      borderCollapse: 'collapse',
    },
    '& table tr td': {
      margin: '0',
      padding: '5px',
      textAlign: 'left',
      fontSize: '16px',
      lineHeight: '19px',
      color: '#000',
      border: '1px solid #000',
    },
    '& table tr th': {
      border: '1px solid #000',
    },
    '& table p': {
      margin: '0',
      padding: '5px',
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '16px',
      color: '#000',
    },
    '& .calculate__title': {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#036',
      alignItems: 'center',
      fontSize: '24px',
      fontWeight: '700',
    },
    '& .calculate__info-date': {
      textAlign: 'right',
      paddingBottom: '30px',
      color: '#000',
    },
  },
});
