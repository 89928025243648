import React, { useState, FC } from 'react';
import {
  DataProvider,
  HttpError,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { withTypes } from 'react-final-form';

import { FormField } from '../../../components/FormField';
import { SubmitButton } from '../../../components/SubmitButton';

import { required } from '../../../utils/validation/validation';

type FormValues = {
  username?: string;
};

const { Form } = withTypes<FormValues>();

interface RequestProps {
  setReset: any;
}

const Request: FC<RequestProps> = ({ setReset }) => {
  const dataProvider: DataProvider = useDataProvider();

  const notify = useNotify();

  const [loading, setLoading] = useState(false);

  const onHandleSubmit = (data: FormValues): void => {
    setLoading(true);
    dataProvider
      .post('auth/forgot-password/request', {
        username: data.username,
      })
      .then(() => {
        setLoading(false);
        setReset(true);
      })
      .catch((error: HttpError) => {
        setLoading(false);
        if (error.status === 422) {
          notify('errors.employee_not_exist', 'warning');
        }
      });
  };

  return (
    <Form onSubmit={onHandleSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            name="username"
            label="ra.auth.username"
            disabled={loading}
            validate={required}
            type="text"
          />
          <div style={{ marginTop: '15px' }}>
            <SubmitButton loading={loading} title="resources.auth.next" />{' '}
          </div>
        </form>
      )}
    </Form>
  );
};

export default Request;
