import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core';
import { useState, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

type DiscountProps = {
  discountCode: string;
  onSubmit(discountCode: string): any;
};

const useStyles = makeStyles(() => ({
  success: {
    color: 'green',
    margin: '15px',
  },
}));

const Discount: FC<DiscountProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState(props.discountCode);
  const classes = useStyles();

  return (
    <>
      <div>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setOpen(true);
          }}
        >
          Ввести промокод
        </Button>
      </div>
      {props.discountCode && (
        <Typography component="p" className={classes.success}>
          Промокод <strong>{props.discountCode}</strong> успешно активирован
        </Typography>
      )}
      <Dialog open={open}>
        <DialogTitle>Ввод промокода</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <TextField
              required
              label="Введите промокод"
              defaultValue={code}
              onChange={(event) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setCode(event.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setCode('');
              setOpen(false);
              props.onSubmit('');
            }}
          >
            Отмена
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              props.onSubmit(code);
              setOpen(false);
            }}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Discount;
