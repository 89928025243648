import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslate } from 'react-admin';
import { TextField } from '@material-ui/core';

type FieldProps = {
  name: string;
  label: string;
  disabled: boolean;
  validate: any;
  type: string;
  maxLength?: number;
  parse?: (v: string) => string;
};

export const FormField: FC<FieldProps> = ({
  label,
  disabled,
  validate,
  name,
  type,
  maxLength,
  parse,
}) => {
  const t = useTranslate();

  return (
    <Field
      name={name}
      disabled={disabled}
      validate={validate}
      type={type}
      parse={parse}
    >
      {({ input, meta: { touched, error, submitError } }) => {
        return (
          <TextField
            error={!!(touched && (error || submitError))}
            helperText={
              touched && (error || submitError) && t(error || submitError)
            }
            {...input}
            label={t(label)}
            fullWidth
            inputProps={{ maxLength }}
          />
        );
      }}
    </Field>
  );
};
