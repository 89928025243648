import React, { FC, useEffect, useCallback } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ICurrentStepProps, IProduct } from '../../../../types';

const Product: FC<ICurrentStepProps> = ({ data, setLoanRequest, nextStep }) => {
  const products = data.productList;
  const handleProduct = useCallback(
    (product: IProduct): void => {
      setLoanRequest({
        ...data,
        product: {
          id: product.id,
          type: product.type,
          title: product.title,
        },
      });

      nextStep();
    },
    [data, nextStep, setLoanRequest],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (products.length === 1) {
      const product: any = products.shift();
      handleProduct({
        id: product.id,
        type: product.type,
        title: product.title,
      });
    }
  }, [handleProduct, products]);

  if (products.length === 0) {
    return (
      <Alert severity="warning">
        Вы не можете подать заявку, обратитесь в службу поддержки
      </Alert>
    );
  }

  return (
    <List component="nav">
      {products.map((product: IProduct, key) => (
        <ListItem
          button
          key={product.id}
          onClick={() => handleProduct(product)}
        >
          <ListItemText primary={`${key + 1}. ${product.title}`} />
        </ListItem>
      ))}
    </List>
  );
};

export default Product;
