import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  button: {
    background: 'none',
    border: 'none',
    display: 'block',
    cursor: 'pointer',
    marginTop: '5px',
    '&:hover, &:focus': {
      color: 'blue',
    },
  },
}));
