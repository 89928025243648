import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundImage:
      'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    backgroundSize: 'cover',
  },
  card: {
    maxWidth: 420,
    marginTop: '6em',
    width: '100%',
  },
  title: {
    padding: '15px',
  },
}));
