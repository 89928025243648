import React, { FC } from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  BooleanInput,
  required,
  email,
  CreateProps,
  useTranslate,
  useNotify,
  HttpError,
} from 'react-admin';

const EmployeeTitle = (resource: any) => {
  const translate = useTranslate();
  const { record } = resource;

  return (
    <span>
      {translate('resources.employees.titles.edit', {
        fullName: `${record.last_name} ${record.first_name}`,
      })}
    </span>
  );
};

const EmployeeEdit: FC<CreateProps> = (props) => {
  const translate = useTranslate();
  const notify = useNotify();

  const onFailure = (error: HttpError): void => {
    // eslint-disable-next-line no-restricted-syntax
    for (const violation of error.body.violations) {
      notify(
        `${translate(
          `resources.employees.fields.${violation.propertyPath}`,
        )}: ${violation.message}`,
        'warning',
      );
    }
  };

  return (
    <Edit {...props} title={<EmployeeTitle />} onFailure={onFailure}>
      <TabbedForm>
        <FormTab label="resources.employees.tabs.general">
          <TextInput
            autoFocus
            source="username"
            fullWidth
            validate={required()}
          />
          <TextInput source="last_name" fullWidth validate={required()} />
          <TextInput source="first_name" fullWidth validate={required()} />
          <TextInput source="phone" fullWidth validate={required()} />
          <TextInput source="email" fullWidth validate={email()} />
          <TextInput source="password" fullWidth />
          <BooleanInput source="enabled" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default EmployeeEdit;
