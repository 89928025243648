import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { DataProvider, useDataProvider } from 'react-admin';
import React, { useState, FC } from 'react';

import { useStyles } from './styles';

type OfferProps = {
  productId: string | number;
  duration: number;
  amount: number;
  userId?: number | null;
  language: string;
  discount?: string;
};

const generateUrl = (props: OfferProps): string => {
  let url = `/wallet/partner/products/${props.productId}/prices/${props.duration}/${props.amount}/offer_html`;

  url += `?lang=${props.language}`;

  if (props.userId) {
    url += `&user_id=${props.userId}`;
  }

  if (props.discount) {
    url += `&discount_code=${props.discount}`;
  }

  return url;
};

const Offer: FC<OfferProps> = (props) => {
  const dataProvider: DataProvider = useDataProvider();
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const loadOffer = () => {
    setLoading(true);

    dataProvider
      .fetch(generateUrl(props), {
        method: 'GET',
        headers: {
          'Content-Type': 'text/html',
        },
      })
      .then((response: any) => {
        setContent(response.data.body);
        setOpen(true);
      })
      .catch((error: any) => {
        alert('Ошибка загрузки преддоговорной информации');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Button
        color="primary"
        onClick={() => {
          loadOffer();
        }}
      >
        Преддоговорная информация
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Преддоговорная информация</DialogTitle>
        <DialogContent>
          <div
            className={classes.contractTable}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Offer;
