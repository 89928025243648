import React, { FC, useState } from 'react';
import {
  DataProvider,
  HttpError,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { withTypes } from 'react-final-form';

import { FormField } from '../../../components/FormField';
import { SubmitButton } from '../../../components/SubmitButton';

import { required } from '../../../utils/validation/validation';

type FormValues = {
  code: string;
  password: string;
};

type ServerError = {
  propertyPath: string;
  message: string;
  code: number | null;
};

const { Form } = withTypes<FormValues>();

const Reset: FC = () => {
  const dataProvider: DataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);

  const onHandleSubmit = (data: FormValues): void => {
    setLoading(true);
    dataProvider
      .post('auth/forgot-password/reset', {
        code: parseInt(data.code, 10),
        password: data.password,
      })
      .then(() => {
        setLoading(false);
        notify('Пароль успешно изменен', 'info');
        redirect('/login');
      })
      .catch((error: HttpError) => {
        setLoading(false);
        if (error.status === 422) {
          const errors = error.body.violations;
          errors.map((item: ServerError) => {
            if (item.propertyPath === 'code') {
              notify('errors.code_error', 'warning');
            }
            return item;
          });
        }
      });
  };

  return (
    <Form onSubmit={onHandleSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            name="code"
            label="resources.auth.code"
            disabled={loading}
            validate={required}
            type="text"
          />
          <FormField
            name="password"
            label="ra.auth.password"
            disabled={loading}
            validate={required}
            type="password"
          />

          <div style={{ marginTop: '15px' }}>
            <SubmitButton
              loading={loading}
              title="resources.auth.change_password"
            />{' '}
          </div>
        </form>
      )}
    </Form>
  );
};

export default Reset;
