import { IDictionary } from '../../types';

export const monthlyIncomeList: Array<IDictionary> = [
  { value: 4000, label: 'monthly_income.less' },
  { value: 7000, label: 'monthly_income.more_less' },
  { value: 10000, label: 'monthly_income.more' },
  { value: 11000, label: 'monthly_income.more_then' },
];

export const educationList: Array<IDictionary> = [
  { value: 1, label: 'education.primary' },
  { value: 2, label: 'education.secondary' },
  { value: 3, label: 'education.special' },
  { value: 4, label: 'education.upper' },
  { value: 5, label: 'education.none' },
];

export const jobPositionList: Array<IDictionary> = [
  { value: 1, label: 'job_position.worker' },
  { value: 2, label: 'job_position.businessman' },
  { value: 3, label: 'job_position.pensioner' },
  { value: 4, label: 'job_position.student' },
  { value: 5, label: 'job_position.decree' },
  { value: 6, label: 'job_position.housewife' },
  { value: 7, label: 'job_position.disabled' },
  { value: 8, label: 'job_position.wallaby' },
];

export const maritalStatusList: Array<IDictionary> = [
  { value: 1, label: 'marital_status.single' },
  { value: 2, label: 'marital_status.married' },
  { value: 3, label: 'marital_status.divorced' },
  { value: 4, label: 'marital_status.widow' },
  { value: 5, label: 'marital_status.cohabitation' },
];
