import { createRef, FC, useState } from 'react';
import {
  Button,
  Datagrid,
  DataProvider,
  Exporter,
  Filter,
  FilterProps,
  FunctionField,
  HttpError,
  List,
  ListProps,
  Loading,
  NumberInput,
  ReferenceField,
  SelectInput,
  TextField,
  useDataProvider,
  useNotify,
  usePermissions,
  useRefresh,
} from 'react-admin';
import moment from 'moment';
import PrintIcon from '@material-ui/icons/Print';
import { LoanConfirmButton } from 'components/LoanConfirmButton';
import { LoanConfirmDropDownButton } from 'components/LoanConfirmDropDownButton';
import { LoanCancelButton } from 'components/LoanCancelButton';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getLoanStatusText, prepareLoanTerm } from '../../utils/helper';

import {
  ACTIVE_LOAN_STATUS,
  AWAITING_SIGN_CONTRACT_STATUS,
  CLOSED_LOAN_STATUS,
  CREDIT_365,
  CREDIT_7,
  CREDIT_LINE,
  GIVE_OUT_FAILED_LOAN_STATUS,
  GIVE_OUT_PROCESSING_LOAN_STATUS,
  REJECTED_LOAN_STATUS,
  REQUEST_LOAN_STATUS,
  ROLE_ADMINISTRATOR,
  SIGNED_LOAN_STATUS,
} from '../../constants';

import { UploadAdditionalDocumentsButton } from './UploadAdditionalDocuments';

import { useStyles } from './styles';
import { DateFilterInput } from '../../components/filters/DateFilterInput';
import { ILoanExport } from '../../types';

const LoanDocuments: FC<{ id: number }> = ({ id }) => {
  const dataProvider = useDataProvider();
  const link = createRef<any>();

  const classes = useStyles();

  const onDownloadAgreement = async (loanId: number): Promise<void> => {
    if (link.current.href) {
      return;
    }

    await dataProvider.downloadLoanAgreement(
      `core/loans/${loanId}/partner-offer`,
    );
  };

  return (
    <button
      type="button"
      className={classes.button}
      onClick={() => onDownloadAgreement(id)}
      ref={link}
    >
      <PrintIcon />
      <span style={{ float: 'right', padding: '4px' }}>
        Документы на печать
      </span>
    </button>
  );
};

const RegenerateDocuments: FC<{ record: any }> = ({ record }) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onRegenerate = async (applicationId: number): Promise<void> => {
    await dataProvider.sendPost(
      `/wallet/partner/wallet-loan-requests/${applicationId}/regenerate_contract`,
      {},
    );
  };

  const handleConfirm = () => {
    setIsLoading(true);
    onRegenerate(record.third_party_request_id).finally(() =>
      setIsLoading(false),
    );
    setOpen(false);
  };

  if (!record.attributes.partner.settings.allow_to_regenerate_contract)
    return null;

  if (isLoading)
    return <CircularProgress size={20} thickness={2} color="inherit" />;

  return (
    <>
      <Button onClick={handleClickOpen}>
        <>Перегенерировать договор</>
      </Button>
      <Dialog open={open}>
        {isLoading ? (
          <Loading loadingPrimary="" loadingSecondary="" />
        ) : (
          <>
            <DialogTitle>Перегенерация договора</DialogTitle>
            <DialogContent>
              <Typography>
                Необходимо подтверждение действия, после чего будет
                перегенерирован договор по кредиту.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                <>Отменить</>
              </Button>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                <>Перегенерировать договор</>
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

const LoanFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <>
    <Filter {...props}>
      <NumberInput source="id" />

      <DateFilterInput
        label="Дата подачи заявки С"
        source="request_date|after"
        after
        prefix="С"
        placeholderText="Выберите дату"
      />
      <DateFilterInput
        label="Дата подачи заявки По"
        source="request_date|before"
        before
        prefix="ПО"
        placeholderText="Выберите дату"
      />
      <SelectInput
        source="status"
        choices={[
          {
            id: REQUEST_LOAN_STATUS,
            name: getLoanStatusText(REQUEST_LOAN_STATUS),
          },
          {
            id: SIGNED_LOAN_STATUS,
            name: getLoanStatusText(SIGNED_LOAN_STATUS),
          },
          {
            id: CLOSED_LOAN_STATUS,
            name: getLoanStatusText(CLOSED_LOAN_STATUS),
          },
          {
            id: ACTIVE_LOAN_STATUS,
            name: getLoanStatusText(ACTIVE_LOAN_STATUS),
          },
          {
            id: REJECTED_LOAN_STATUS,
            name: getLoanStatusText(REJECTED_LOAN_STATUS),
          },
          {
            id: GIVE_OUT_PROCESSING_LOAN_STATUS,
            name: getLoanStatusText(GIVE_OUT_PROCESSING_LOAN_STATUS),
          },
          {
            id: GIVE_OUT_FAILED_LOAN_STATUS,
            name: getLoanStatusText(GIVE_OUT_FAILED_LOAN_STATUS),
          },
          {
            id: AWAITING_SIGN_CONTRACT_STATUS,
            name: getLoanStatusText(AWAITING_SIGN_CONTRACT_STATUS),
          },
        ]}
      />
    </Filter>
  </>
);

const exporter: Exporter = (
  loans: ILoanExport[],
  fetchRelatedRecords: (
    data: any,
    field: string,
    resource: string,
  ) => Promise<any>,
  dataProvider: DataProvider,
) => {
  const loansForExport = loans.map((loan: ILoanExport) => {
    return loan?.id;
  });
  dataProvider.downloadExelLoans(loansForExport);
};

const LoansList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions();
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider: DataProvider = useDataProvider();

  const handleOnLoanCancel = (loanId: number, reasonId: number) => {
    dataProvider
      .sendPost(`/wallet/partner/wallet-loan-requests/${loanId}/cancel`, {
        reason: reasonId,
      })
      .then(() => {
        notify('Заявка успешно отменена.', 'success');
      })
      .catch((e: HttpError) => {
        notify('Ошибка отмены заявки. Повторите действие позже.', 'warning');
      })
      .finally(() => {
        refresh();
      });
  };

  return (
    <List
      {...props}
      perPage={25}
      // sort={{ field: 'id', order: 'desc' }}
      filters={<LoanFilter />}
      bulkActionButtons={false}
      exporter={permissions !== ROLE_ADMINISTRATOR ? false : exporter}
      title="resources.loans.label"
      filterDefaultValues={{ status: AWAITING_SIGN_CONTRACT_STATUS }}
    >
      <Datagrid optimized>
        <TextField source="id" />
        <TextField source="user.full_name" />
        <FunctionField
          source="product"
          render={(record: any) => {
            return (
              <>
                {record.product.short
                  ? CREDIT_7
                  : record.product.long
                  ? CREDIT_365
                  : CREDIT_LINE}
              </>
            );
          }}
        />
        <TextField source="request_amount" label="Сумма" />
        <FunctionField
          source="term"
          label="Срок"
          render={(record: any) =>
            prepareLoanTerm(
              record.term,
              record.product.short ? CREDIT_7 : CREDIT_365,
            )
          }
        />
        <FunctionField
          source="status"
          render={(record: any) => (
            <div>
              <div>{getLoanStatusText(record.status)}</div>
              {record.status === REQUEST_LOAN_STATUS ? (
                <ul>
                  {record.last_hold?.reasons.map((reason: any) => (
                    <li key={reason.id}>{reason.name}</li>
                  ))}
                </ul>
              ) : (
                <div>
                  {
                    record.last_hold?.reasons[
                      record.last_hold?.reasons.length - 1
                    ]?.name
                  }
                </div>
              )}
            </div>
          )}
        />
        <FunctionField
          source="request_date"
          label="Дата подачи заявки"
          render={(record: any) => {
            return moment(record.request_date).format('DD.MM.YYYY');
          }}
        />
        {permissions === ROLE_ADMINISTRATOR && (
          <ReferenceField
            label="Сотрудник"
            source="employee_id"
            reference="employees"
          >
            <FunctionField
              render={(record: any) => {
                return `${record.first_name} ${record.last_name}`;
              }}
            />
          </ReferenceField>
        )}
        <FunctionField
          source="agreements"
          render={(record: any) => {
            return record.status === AWAITING_SIGN_CONTRACT_STATUS ? (
              <>
                <LoanDocuments id={record.id} />
                <RegenerateDocuments record={record} />
              </>
            ) : (
              ''
            );
          }}
        />

        <FunctionField
          source=""
          render={(record: any) => {
            const signMethods = record.attributes.partner?.sign_methods;

            const isAllowCancelLoan =
              record.attributes.partner?.settings.allow_cancel_loan_request;
            // eslint-disable-next-line no-nested-ternary
            return record.status === AWAITING_SIGN_CONTRACT_STATUS ? (
              signMethods.length > 1 ? (
                <>
                  <LoanConfirmDropDownButton
                    methods={signMethods}
                    id={record.id}
                  />
                  {isAllowCancelLoan && (
                    <LoanCancelButton
                      id={record.third_party_request_id}
                      onConfirmCancel={handleOnLoanCancel}
                    />
                  )}
                </>
              ) : (
                <>
                  <LoanConfirmButton id={record.id} methods={signMethods} />
                  {isAllowCancelLoan && (
                    <LoanCancelButton
                      id={record.third_party_request_id}
                      onConfirmCancel={handleOnLoanCancel}
                    />
                  )}
                </>
              )
            ) : (
              ''
            );
          }}
        />
        <FunctionField
          source=""
          render={(record: any) => {
            return record.status === REQUEST_LOAN_STATUS ? (
              <UploadAdditionalDocumentsButton userId={record.user.id} />
            ) : (
              ''
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default LoansList;
