import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { DataProvider } from 'react-admin';

import dataProviderFactory from './dataProvider';
import App from './App';

const prepareDataProvider = async (): Promise<{
  dataProvider: DataProvider;
}> => {
  const dataProvider = await dataProviderFactory();
  return { dataProvider };
};

prepareDataProvider().then(({ dataProvider }) => {
  ReactDOM.render(
    <App dataProvider={dataProvider} />,
    document.getElementById('root'),
  );
});
