import decodeJwt from 'jwt-decode';
import { User, JwtTokenPayload } from './types';

class JWTToken {
  // eslint-disable-next-line class-methods-use-this
  public setToken(token: string, refreshToken: string): void {
    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refreshToken);
  }

  // eslint-disable-next-line class-methods-use-this
  public destroy(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  }

  // eslint-disable-next-line class-methods-use-this
  public getToken(): string {
    const token = localStorage.getItem('token');

    if (token === null) {
      throw new Error('JWTToken not found');
    }

    return token.toString();
  }

  // eslint-disable-next-line class-methods-use-this
  public hasToken(): boolean {
    return localStorage.getItem('token') !== null;
  }

  // eslint-disable-next-line class-methods-use-this
  public hasRefreshToken(): boolean {
    return localStorage.getItem('refresh_token') !== null;
  }

  // eslint-disable-next-line class-methods-use-this
  public getRefreshToken(): string {
    const refreshToken = localStorage.getItem('refresh_token');

    if (refreshToken === null) {
      throw new Error('Refresh Token not found');
    }

    return refreshToken;
  }

  public isNotExpired(): boolean {
    if (!this.hasToken()) {
      return false;
    }

    const token = this.decodeToken();

    return token.exp > new Date().getTime() / 1000;
  }

  public getUser(): User {
    if (!this.isNotExpired()) {
      throw new Error('Token expired');
    }

    const token = this.decodeToken();

    return {
      id: token.id,
      fullName: token.username,
      role: token.roles[0],
    };
  }

  private decodeToken(): JwtTokenPayload {
    return decodeJwt(this.getToken());
  }
}

export default new JWTToken();
