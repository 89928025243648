import { useEffect } from 'react';
import {
  DataProvider,
  Layout,
  LayoutProps,
  useDataProvider,
} from 'react-admin';

const ExportedLayout = (props: LayoutProps) => {
  const dataProvider: DataProvider = useDataProvider();
  console.log('ExportedLayout', props);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      // dataProvider
      //   .request('/third-party/me')
      //   .then(({ data: response }: any) => {
      //     const brandName = response.data.attributes?.partner?.brand_name;
      //     window.document.title = `${brandName} - Aventus Finance Admin`;
      //   });
    }
  }, [dataProvider, token]);

  return <Layout {...props} />;
};

export default ExportedLayout;
