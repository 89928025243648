import { FC } from 'react';

import './styles.css';

type ErrorMessageProps = {
  message: string;
};

export const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  return <div className="flex flex-col items-center error">{message}</div>;
};
