import React, { FC, useState } from 'react';
import { withTypes } from 'react-final-form';
import {
  useTranslate,
  HttpError,
  Notification,
  useDataProvider,
  useNotify,
  DataProvider,
} from 'react-admin';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  Paper,
  CircularProgress,
  Icon,
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { Close, CloudUpload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { DialogComponent } from '../../../components/DialogComponent';

import { required } from '../../../utils/validation/validation';
import { FormField } from '../../../components/FormField';
import { SubmitButton } from '../../../components/SubmitButton';
import { IViolation } from '../../../types';
import { CODE_SIGN_METHOD } from '../../../constants';

import JWTToken from '../../../JWTToken';

type LoanConfirmProps = {
  onClose: () => void;
  id: number;
  method: number;
};

type FormValues = {
  id: number;
  code: number;
};

type LoanConfirmFormProps = {
  onClose: () => void;
  id: number;
};

const { Form } = withTypes<FormValues>();

export const LoanConfirm: FC<LoanConfirmProps> = ({ id, onClose, method }) => {
  return (
    <DialogComponent title="Подтверждение кредита">
      {method === CODE_SIGN_METHOD ? (
        <LoanConfirmCodeForm onClose={onClose} id={id} />
      ) : (
        <LoanConfirmDocumentForm onClose={onClose} id={id} />
      )}
    </DialogComponent>
  );
};

const LoanConfirmCodeForm: FC<LoanConfirmFormProps> = ({ id, onClose }) => {
  const dataProvider: DataProvider = useDataProvider();
  const translate = useTranslate();

  const onSubmit = (formData: FormValues): void => {
    return dataProvider
      .sendPost(`/core/loans/${id}/sign-contract`, formData)
      .then(() => {
        window.location.reload();
      })
      .catch((e: HttpError) => {
        if (e.status === 422) {
          const violation: IViolation = e.body.violations[0];
          return { code: violation.message };
        }
        return '';
      });
  };

  return (
    <DialogContent>
      <DialogContentText>
        Для подтверждения кредита был отправлен на номер телефона смс с кодом
        подтверждения.
      </DialogContentText>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormControl fullWidth>
                <FormField
                  name="code"
                  label="Введите код"
                  disabled={submitting}
                  validate={required}
                  type="text"
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <SubmitButton
                  loading={submitting}
                  title="ra.action.send_loan_request"
                />
                <Button onClick={onClose}>{translate('ra.action.undo')}</Button>
              </FormControl>
              <Notification />
            </form>
          );
        }}
      />
    </DialogContent>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    cursor: 'pointer',
    color: '#575757',
    marginTop: '10px',
    marginBottom: '10px',
    height: '150px',
    maxHeight: '200px',
    '& img': {
      width: '100%',
      height: '120px',
    },
  },
  icon: {
    color: '#888',
    fontSize: '38px',
  },
  overlay: {
    background: '#333',
    opacity: '0.6',
  },
  closeIcon: {
    cursor: 'pointer',
    width: '100%',
    height: '20px',
    border: '0px',
    padding: '3px',
    position: 'absolute',
    bottom: '0',
    left: '0',
  },
  closeButton: {
    right: '4px',
    top: '4px',
    position: 'absolute',
  },
  submit: {
    background: '#3f51b5',
    color: 'white',
    '&:disabled': {
      background: '#5966b6',
      color: 'white',
    },
  },
  cloudWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));

export const LoanConfirmDocumentForm: FC<LoanConfirmFormProps> = ({
  id,
  onClose,
}) => {
  const [document, setDocument] = useState<{
    name: string;
  } | null>(null);

  const [loaded, setLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider: DataProvider = useDataProvider();

  const classes = useStyles();

  const onSubmit = (): void => {
    setIsSubmitting(true);
    return onUploadDocument();
  };

  const onSignContract = (): void => {
    return dataProvider
      .sendPost(`/core/loans/${id}/sign-contract`, { sign_method: 2 })
      .then(() => {
        window.location.reload();
      })
      .catch((e: HttpError) => {
        if (e.status === 422) {
          const violation: IViolation = e.body.violations[0];
          return { code: violation.message };
        }
        return '';
      });
  };

  const onSetDocument = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      setDocument(e.target.files[0]);
      setLoaded(true);
    }
  };

  const onUploadDocument = (): any => {
    const apiUrl = process.env?.REACT_APP_API_URL || '';

    const uri = new URL(
      `${apiUrl.replace(
        'api/third-party',
        '',
      )}/core/loans/${id}/agreement-copies`,
    );

    const formData = new FormData();
    if (document) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.append('file', document);
    }

    return fetch(uri.toString(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JWTToken.getToken()}`,
      },
      body: formData,
    })
      .then(() => onSignContract())
      .catch(() => {
        setIsSubmitting(false);
        notify('Файл не был загружен');
      });
  };

  const onCheckSubmit = () => {
    if (loaded) {
      onSubmit();
    } else {
      notify('Файл не был загружен');
    }
  };

  return (
    <DialogContent>
      <DialogContentText>
        Для подтверждения кредита загрузите скан документа
      </DialogContentText>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          <Paper variant="outlined" className={classes.root}>
            {document?.name ? (
              <div
                style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PictureAsPdfIcon fontSize="large" />

                <Button
                  className={classes.closeIcon}
                  color="secondary"
                  onClick={() => setDocument(null)}
                >
                  <Close />
                  Удалить
                </Button>
              </div>
            ) : (
              <div className={classes.cloudWrapper}>
                <input
                  accept="application/pdf"
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={onSetDocument}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  htmlFor="contained-button-file"
                  className={classes.label}
                >
                  <CloudUpload className={classes.icon} />
                </label>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>

      <DialogActions>
        <Button
          disabled={isSubmitting}
          className={classes.submit}
          onClick={onCheckSubmit}
        >
          {isSubmitting && <CircularProgress size={25} thickness={2} />}
          {translate('ra.action.send_loan_request')}
        </Button>
        <Button onClick={onClose}>{translate('ra.action.undo')}</Button>
      </DialogActions>
      <Notification />
    </DialogContent>
  );
};
