import { TranslationMessages } from 'react-admin';

const customRussianMessages: TranslationMessages = {
  ra: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    action: {
      add_filter: 'Добавить фильтр',
      add: 'Добавить',
      back: 'Назад',
      bulk_actions:
        '1 выбран |||| %{smart_count} выбрано |||| %{smart_count} выбрано',
      cancel: 'Отмена',
      clear_input_value: 'Очистить',
      create_item: '',
      clone: 'Дублировать',
      confirm: 'Подтвердить',
      create: 'Создать',
      delete: 'Удалить',
      edit: 'Редактировать',
      export: 'Экспорт',
      list: 'Список',
      refresh: 'Обновить',
      remove_filter: 'Убрать фильтр',
      remove: 'Удалить',
      save: 'Сохранить',
      search: 'Поиск',
      show: 'Просмотр',
      sort: 'Сортировка',
      undo: 'Отменить',
      unselect: 'Не выбрано',
      expand: 'Раскрыть',
      close: 'Закрыть',
      open_menu: 'Открыть меню',
      close_menu: 'Закрыть меню',
      send_loan_request: 'Отправить заявку',
      cancel_loan: 'Отменить заявку',
      update: 'Обновить',
      move_up: 'Переместить вверх',
      move_down: 'Переместить вниз',
      upload_docs: 'Загрузить документы',
    },
    boolean: {
      true: 'Да',
      false: 'Нет',
      null: '',
    },
    page: {
      create: 'Создать %{name}',
      dashboard: 'Главная',
      edit: '%{name} #%{id}',
      error: 'Что-то пошло не так',
      list: '%{name}',
      loading: 'Загрузка',
      not_found: 'Не найдено',
      show: '%{name} #%{id}',
      empty: 'Нет %{name}.',
      invite: 'Вы хотите добавить еще одну?',
    },
    input: {
      file: {
        upload_several: 'Перетащите файлы сюда или нажмите для выбора.',
        upload_single: 'Перетащите файл сюда или нажмите для выбора.',
      },
      image: {
        upload_several: 'Перетащите изображения сюда или нажмите для выбора.',
        upload_single: 'Перетащите изображение сюда или нажмите для выбора.',
      },
      references: {
        all_missing: 'Связанных данных не найдено',
        many_missing: 'Некоторые из связанных данных не доступны',
        single_missing: 'Связанный объект не доступен',
      },
      password: {
        toggle_visible: 'Скрыть пароль',
        toggle_hidden: 'Показать пароль',
      },
    },
    message: {
      about: 'Справка',
      are_you_sure: 'Вы уверены?',
      bulk_update_content: 'Вы уверены, что хотите обновить контент ?',
      bulk_update_title: 'Вы уверены, что хотите обновить заголовок?',
      bulk_delete_content:
        'Вы уверены, что хотите удалить %{name}? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count} ? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count} ?',
      bulk_delete_title:
        'Удалить %{name} |||| Удалить %{smart_count} %{name} |||| Удалить %{smart_count} %{name}',
      delete_content: 'Вы уверены что хотите удалить этот объект',
      delete_title: 'Удалить %{name} #%{id}',
      details: 'Описание',
      error: 'В процессе запроса возникла ошибка, и он не может быть завершен',
      invalid_form: 'Форма заполнена неверно, проверьте, пожалуйста, ошибки',
      loading: 'Идет загрузка, пожалуйста, подождите...',
      no: 'Нет',
      not_found: 'Ошибка URL или вы следуете по неверной ссылке',
      yes: 'Да',
      unsaved_changes:
        'Некоторые из ваших изменений не были сохранены. Вы уверены, что хотите их игнорировать?',
    },
    navigation: {
      no_results: 'Результатов не найдено',
      no_more_results:
        'Страница %{page} выходит за пределы нумерации, попробуйте предыдущую',
      page_out_of_boundaries: 'Страница %{page} вне границ',
      page_out_from_end: 'Невозможно переместиться дальше последней страницы',
      page_out_from_begin: 'Номер страницы не может быть меньше 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} из %{total}',
      page_rows_per_page: 'Строк на странице:',
      next: 'Следующая',
      prev: 'Предыдущая',
      skip_nav: 'Пропустить',
    },
    sort: {
      sort_by: 'Сортировать по %{field} %{order}',
      ASC: 'возрастанию',
      DESC: 'убыванию',
    },
    auth: {
      auth_check_error: 'Пожалуйста, авторизуйтесь для продолжения работы',
      user_menu: 'Профиль',
      username: 'Имя пользователя',
      password: 'Пароль',
      sign_in: 'Войти',
      sign_in_error: 'Ошибка аутентификации, попробуйте снова',
      logout: 'Выйти',
    },
    notification: {
      not_authorized: 'Вы не авторизованы',
      updated:
        'Элемент обновлен |||| %{smart_count} обновлено |||| %{smart_count} обновлено',
      created: 'Элемент создан',
      deleted:
        'Элемент удален |||| %{smart_count} удалено |||| %{smart_count} удалено',
      bad_item: 'Элемент не валиден',
      item_doesnt_exist: 'Элемент не существует',
      http_error: 'Ошибка сервера',
      data_provider_error: 'Ошибка dataProvider, проверьте консоль',
      i18n_error: 'Не удалось загрузить перевод для указанного языка',
      canceled: 'Операция отменена',
      logged_out:
        'Ваша сессия завершена, попробуйте переподключиться/войти снова',
    },
    validation: {
      required: 'Обязательно для заполнения',
      minLength: 'Минимальное кол-во символов %{min}',
      maxLength: 'Максимальное кол-во символов %{max}',
      minValue: 'Минимальное значение %{min}',
      maxValue: 'Значение может быть %{max} или меньше',
      number: 'Должно быть цифрой',
      email: 'Некорректный email',
      oneOf: 'Должно быть одним из: %{options}',
      regex: 'Должно быть в формате (regexp): %{pattern}',
    },
  },
  resources: {
    auth: {
      forgot_password: 'Забыли пароль ?',
      forgot_password_request_title:
        'Пожалуйста, укажите <strong>логин</strong> или <strong>телефон</strong>, для восстановления пароля.',
      forgot_password_reset_title: 'Изменение пароля',
      phone: 'Введите номер телефона',
      next: 'Далее',
      change_password: 'Изменить пароль',
      username: 'Введите логин или номер телефона',
      password: 'Введите новый пароль',
      code: 'Код из смс',
      password_changed_successfully: 'Пароль успешно изменен',
    },
    employees: {
      label: 'Сотрудники',
      tabs: {
        general: 'Общая информация',
      },
      fields: {
        id: '№',
        username: 'Логин',
        last_name: 'Фамилия',
        first_name: 'Имя',
        phone: 'Номер телефона',
        email: 'E-mail',
        password: 'Пароль',
        enabled: 'Активность (Включить/Выключить)',
      },
      titles: {
        create: 'Добавить нового сотрудника',
        edit: 'Редактирование сотрудника: %{fullName}',
      },
    },
    requests: {
      label: 'Незавершенные заявки',
      tabs: {
        general: 'Общая информация',
      },
      fields: {
        id: '№',
        created_at: 'Дата создания',
        confirmed: 'Статус подтверждения',
      },
    },
    loans: {
      label: 'Кредиты',
      tabs: {
        general: 'Общая информация',
      },
      fields: {
        id: '№',
        'user.full_name': 'Имя клиента',
        status: 'Статус',
        agreements: 'Документы',
        product: 'Продукт',
      },
      titles: {
        create: 'Добавить нового сотрудника',
        edit: 'Редактирование сотрудника: %{fullName}',
      },
    },
    loan_request: {
      label: 'Подать заявку',
    },
    calculator: {
      label: 'Калькулятор',
    },
  },
  validation: {
    user_already_has_loan: 'Уже есть незакрытые займы',
    unknown_price: 'Прайслист с данными параметрами не найден',
    unknown_product:
      'Некорректные настройки. Обратитесь в службу технической поддержки',
    unknown_discount: 'Неверный промокод',
    unknown_loan_term_or_amount: 'Некорректно указаны сумма или срок кредита',
    auth_code_invalid_or_expired:
      'Код введен не правильно или истекло время действия кода',
    incorrect_idnp:
      'Некорректный IDNP. Проверьте правильность введенных данных',
  },
  dictionary: {
    loan_request_statuses: {
      request: 'Заявка на кредит',
      approved: 'Ожидается подпись договора',
      signed: 'Договор подписан',
      giveout_in_process: 'В обработке. Ожидает получения денежных средств.',
      giveout_failed: 'Неудачная обработка. Ошибка при передаче денег.',
      active: 'Активный кредит',
      closed: 'Закрытый кредит',
      denied: 'Отклоненный кредит',
    },
    education: {
      primary: 'Школа (1-4 класс)',
      secondary: 'Лицей/Школа',
      special: 'Колледж',
      upper: 'Высшее',
      none: 'Нет образования',
    },
    job_position: {
      worker: 'Наемный сотрудник',
      businessman: 'Бизнесмен/Предпринимательство',
      pensioner: 'Пенсионер',
      student: 'Студент',
      decree: 'Декретный отпуск',
      housewife: 'Домохозяйка',
      disabled: 'Человек с ограниченными возможностями здоровья',
      wallaby: 'Самозанятость',
    },
    marital_status: {
      single: 'Не замужем/Не женат',
      married: 'Замужем/Женат',
      divorced: 'Разведен(а)',
      widow: 'Вдова/Вдовец',
      cohabitation: 'Совместное проживание',
    },
    monthly_income: {
      less: '0 - 4000 MDL',
      more_less: '4001 - 7000 MDL',
      more: '7001 - 10000 MDL',
      more_then: 'Более 10000 MDL',
    },
  },
  errors: {
    employee_not_exist: 'Сотрудник не найден',
    code_error: 'Код введен не правильно или истек',
    employee_blocked_till_date:
      'Извините, ваша заявка отклонена. К сожалению на данный момент не можете подать заявку. Причина: клиент заблокирован до %{date}',
    employee_blocked:
      'Извините, ваша заявка отклонена. К сожалению на данный момент не можете подать заявку. Причина: клиент заблокирован.',
  },
  loan_request: {
    client_language: 'Язык обслуживания клиента',
    idnp_label: 'Введите IDNP',
    give_out_method: 'Метод выдачи',
    account_number: 'Номер счета',
    commodity_category: 'Категория товара',
    commodity_name: 'Наименование товара',
    deny_reason: 'Выберите причину отказа',
  },
};

export default customRussianMessages;
