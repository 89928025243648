import { FC, useState } from 'react';
import { LoadingIndicator } from 'react-admin';

import { DialogComponent } from 'components/DialogComponent';
import { UploadAdditionalDocumentsComponent } from './UploadAdditionalDocumentsComponent';
import { UploadAdditionalDocumentsError } from './UploadAdditionalDocumentsError';
import { UploadAdditionalDocumentsSuccess } from './UploadAdditionalDocumentsSuccess';

export const UploadAdditionalDocumentsModal: FC<{
  userId: number;
  onClose: () => void;
}> = ({ userId, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDocsUploadedSuccessfully, setIsDocsUploadedSuccessfully] =
    useState(false);
  const [docsUploadError, setDocsUploadError] = useState('');
  const isShowUploadForm =
    !isDocsUploadedSuccessfully && !docsUploadError && !isLoading;
  const isDocsUploadError = docsUploadError !== '';

  const handleRetryUploadAdditionalDocs = () => {
    setDocsUploadError('');
  };

  let component: any;
  switch (true) {
    case isShowUploadForm:
      component = (
        <UploadAdditionalDocumentsComponent
          userId={userId}
          onClose={onClose}
          isDocsUploadedSuccessfully={isDocsUploadedSuccessfully}
          setDocsUploadError={setDocsUploadError}
          setIsDocsUploadedSuccessfully={setIsDocsUploadedSuccessfully}
          setIsLoading={setIsLoading}
        />
      );
      break;
    case isDocsUploadedSuccessfully:
      component = <UploadAdditionalDocumentsSuccess onClose={onClose} />;
      break;
    case isDocsUploadError:
      component = (
        <UploadAdditionalDocumentsError
          error={docsUploadError}
          onClose={onClose}
          onRetry={handleRetryUploadAdditionalDocs}
        />
      );
      break;
    default:
      component = <LoadingIndicator />;
      break;
  }

  return (
    <DialogComponent title="Загрузка документов">{component}</DialogComponent>
  );
};
