import React, { FC } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { IDictionary } from '../../../../types';

interface ISelectDictionaryProps {
  dictionary: Array<IDictionary>;
  register: UseFormRegisterReturn;
}

const SelectDictionary: FC<ISelectDictionaryProps> = (props) => {
  const { register, dictionary } = props;
  const translate = useTranslate();

  return (
    <Select {...register} defaultValue="">
      {dictionary.map((item) => (
        <MenuItem value={item.value} key={item.value}>
          {translate(`dictionary.${item.label}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectDictionary;
